import React from 'react'

function TableShimmerTab() {
  return (
    <div>

        <table className='pro-table'>
            <thead>
                <tr>
                    <th width={60}><span className={`letter shine`}></span></th>
                    <th width={`15%`}><span className={`letter shine`}></span></th>
                    <th width={`10%`}><span className={`letter shine`}></span></th>
                    <th width={`30%`}><span className={`letter shine`}></span></th>
                    <th width={`40%`}><span className={`letter shine`}></span></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                </tr>
                <tr>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                </tr>
                <tr>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                </tr>
                <tr>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                </tr>
                <tr>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                </tr>
                <tr>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                    <td><span className={`letter shine`}></span></td>
                </tr>
            </tbody>
        </table>


        <div className={`pro-p-6`}>
            <div className="row">
                <div className="col-4 pro-mb-5">
                    <div className="col-4">
                        <span className={`letter shine pro-mb-1`}></span>
                        <span className={`letter sub shine pro-mb-4`}></span>
                    </div>
                    <span className={`letter para shine pro-mb-1`}></span>
                    <span className={`letter para shine pro-mb-1`}></span>
                    <span className={`letter para shine pro-mb-1`}></span>
                    <span className={`letter para shine pro-mb-1`}></span>
                </div>
                <div className="col-4 pro-mb-5">
                    <div className="col-4">
                        <span className={`letter shine pro-mb-1`}></span>
                        <span className={`letter sub shine pro-mb-4`}></span>
                    </div>
                    <span className={`letter para shine pro-mb-1`}></span>
                    <span className={`letter para shine pro-mb-1`}></span>
                    <span className={`letter para shine pro-mb-1`}></span>
                    <span className={`letter para shine pro-mb-1`}></span>
                </div>
                <div className="col-4 pro-mb-5">
                    <div className="col-4">
                        <span className={`letter shine pro-mb-1`}></span>
                        <span className={`letter sub shine pro-mb-4`}></span>
                    </div>
                    <span className={`letter para shine pro-mb-1`}></span>
                    <span className={`letter para shine pro-mb-1`}></span>
                    <span className={`letter para shine pro-mb-1`}></span>
                    <span className={`letter para shine pro-mb-1`}></span>
                </div>
                <div className="col-4 pro-mb-5">
                    <div className="col-4">
                        <span className={`letter shine pro-mb-1`}></span>
                        <span className={`letter sub shine pro-mb-4`}></span>
                    </div>
                    <span className={`letter para shine pro-mb-1`}></span>
                    <span className={`letter para shine pro-mb-1`}></span>
                    <span className={`letter para shine pro-mb-1`}></span>
                    <span className={`letter para shine pro-mb-1`}></span>
                </div>
                <div className="col-4 pro-mb-5">
                    <div className="col-4">
                        <span className={`letter shine pro-mb-1`}></span>
                        <span className={`letter sub shine pro-mb-4`}></span>
                    </div>
                    <span className={`letter para shine pro-mb-1`}></span>
                    <span className={`letter para shine pro-mb-1`}></span>
                    <span className={`letter para shine pro-mb-1`}></span>
                    <span className={`letter para shine pro-mb-1`}></span>
                </div>
                <div className="col-4 pro-mb-5">
                    <div className="col-4">
                        <span className={`letter shine pro-mb-1`}></span>
                        <span className={`letter sub shine pro-mb-4`}></span>
                    </div>
                    <span className={`letter para shine pro-mb-1`}></span>
                    <span className={`letter para shine pro-mb-1`}></span>
                    <span className={`letter para shine pro-mb-1`}></span>
                    <span className={`letter para shine pro-mb-1`}></span>
                </div>
                
            </div>
        </div>

    </div>
  )
}

export default TableShimmerTab