import React, { useState } from "react";
import Style from "./Table.module.scss";
import Select from "react-select";
import { FaSort } from "react-icons/fa";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { HiOutlineUserCircle } from "react-icons/hi";

const Table = (props) => {
  const { rows, columns, checkboxSelection } = props;
  const options = [
    { value: "pending", label: "Pending" },
    { value: "completed", label: "Completed" },
    { value: "onprogress", label: "On Progress" },
  ];
  const [checkedState, setCheckedState] = useState(
    new Array(rows.length).fill(false)
  );
  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };
  const handleCheckAll = () => {
    if (checkedState.every((c) => c === true)) {
      setCheckedState((prevState) => prevState.map((c) => (c = false)));
    } else {
      setCheckedState((prevState) => prevState.map((c) => (c = true)));
    }
  };
  const checkedRowCount = checkedState.filter((c) => c === true).length;
  return (
    <div className={`pro-pt-3 ${Style.proTableWrp}`}>
      <div
        className={`${Style.tableActions} ${
          checkedRowCount ? Style.actionShow : ""
        }`}
      >
        <div className="pro-btn-group pro-pb-2">
          <button className="pro-btn pro-btn-secondary">
            {checkedRowCount} Selected
          </button>
          <button className="pro-btn pro-btn-secondary">
            <MdOutlineEdit size={14} className="pro-me-2" />
            Edit
          </button>
          <button className="pro-btn pro-btn-secondary">
            <RiDeleteBin6Line size={14} className="pro-me-2" />
            Delete
          </button>
          <button className="pro-btn pro-btn-secondary">
            <HiOutlineUserCircle size={14} className="pro-me-2" />
            Assign
          </button>
        </div>
      </div>
      <table className="pro-table">
        <thead>
          <tr>
            {checkboxSelection ? (
              <th className="checkbox-cell">
                <div className="pro-check-box">
                  <input
                    type="checkbox"
                    className="pro-check"
                    onChange={handleCheckAll}
                    checked={checkedState.every((c) => c === true)}
                  />
                </div>
              </th>
            ) : (
              ""
            )}
            {columns.map((tableHead, index) => {
              return (
                <th
                  key={index}
                  className={tableHead.sortable ? Style.sortable : ``}
                >
                  {tableHead.name}
                  {tableHead.sortable ? <FaSort /> : ""}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map((tableData, index) => {
            return (
              <tr key={index}>
                {checkboxSelection ? (
                  <td className="checkbox-cell">
                    <div className="pro-check-box">
                      <input
                        type="checkbox"
                        className="pro-check"
                        checked={checkedState[index]}
                        onChange={() => handleOnChange(index)}
                      />
                    </div>
                  </td>
                ) : (
                  ""
                )}
                {columns.map((tableHead, tdIndex) => {
                  return (
                    <td key={tdIndex}>
                      {/* {typeof tableData[tableHead.field] === "string"?(<p className="pro-mb-0">{tableData[tableHead.field]}</p>):} */}
                      {tableHead.field === `stage` ? (
                        <Select
                          options={options}
                          classNamePrefix="pro-input"
                          className={"pro-input"}
                          defaultValue={options.find(
                            (o) => o.label === tableData[tableHead.field]
                          )}
                        />
                      ) : tableHead.type === `link`  ? (
                        <a href={tableData[tableHead.field].link} className="pro-fw-bold">
                          {tableData[tableHead.field].label}
                        </a>
                      ) : (
                        <p className="pro-mb-0">{tableData[tableHead.field]}</p>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
