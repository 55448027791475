import React from 'react';
import Style from './activityMonitorTab.module.scss'
import ActivityMonitor from '../../Ui-widgets/ActivityMonitor';
import { IconText, Image } from '@wac-ui-dashboard/wac_component_library';


function ActivityMonitorTab() {
    return (
        <div className={Style.root}>
           <ActivityMonitor  
           className={`pro-py-4`} 
           icon={ <span className="material-symbols-outlined">home</span>}
           title={`Viewed a user profile`}></ActivityMonitor>



           <ActivityMonitor 
                className={`pro-py-4`} 
                icon={ <span className="material-symbols-outlined">home</span>} 
                title={`Purchased an add on package to the existing ‘Gold’ package`}>
            </ActivityMonitor>
           <ActivityMonitor 
                className={`pro-py-4`} 
                icon={ <span className="material-symbols-outlined">home</span>} 
                title={`Viewed 10 user profiles`}
                children={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima incidunt reiciendis eveniet facere atque repellendus. Adipisci ex ad aut veritatis placeat minima. Fugit exercitationem voluptatibus nesciunt maxime quo impedit dolorum.`}>
            </ActivityMonitor>
           <ActivityMonitor 
                className={`pro-py-4`} 
                icon={ <span className="material-symbols-outlined">home</span>} 
                title={`Shortlisted a user profile`}>
            </ActivityMonitor>
           <ActivityMonitor 
                className={`pro-py-4`} 
                icon={ <span className="material-symbols-outlined">home</span>} 
                title={`Showed interest on 4 user profiles`}
                children={
                <>
                    <span className='avatar-sqr'><Image src={`https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg`} width={24} height={24}/></span>
                    <span className='avatar-sqr'><Image src={`https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg`} width={24} height={24}/></span>
                    <span className='avatar-sqr'><Image src={`https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg`} width={24} height={24}/></span>
                    <span className='avatar-sqr'><Image src={`https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg`} width={24} height={24}/></span>
                </>
                }
                >
            </ActivityMonitor>
           
           
           <ActivityMonitor 
                className={`pro-py-4`} 
                icon={ <span className="material-symbols-outlined">home</span>} 
                title={`Purchased an add on package to the existing ‘Gold’ package`}>
            </ActivityMonitor>
           
           
           
           <ActivityMonitor 
                className={`pro-py-4`} 
                icon={ <span className="material-symbols-outlined"> settings </span>} 
                title={`Purchased a new yearly package`}
                children={ 

                <>
               <span className='pro-p-1 pro-pe-4'>
               <IconText title={`Jane Doe`} icon={<div className={`pro-avatar`}><img src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg" alt=""/></div>}>IconText</IconText>
               </span>
               <span className='pro-p-1 pro-pe-4'>
               <IconText title={`Jane Doe`} icon={<div className={`pro-avatar`}><img src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg" alt=""/></div>}>IconText</IconText>
               </span>
                
                </>
                
            }
                >
            </ActivityMonitor>

             
           <ActivityMonitor 
                className={`pro-py-4`} 
                icon={ <span className="material-symbols-outlined">home</span>} 
                title={`Signed in as ‘Nithin Varghese’`}>
            </ActivityMonitor>
       
        </div>
    )
}



export default React.memo(ActivityMonitorTab);