import React from 'react';
import Style from './authTab.module.scss'
import { Auth } from '@wac-ui-dashboard/wac_component_library'


function AuthTab() {
    return (
        <div className={Style.root}>
            <div className={"text pro-mb-4"}>
                <h3 className={`pro-ttl h3 pro-mb-3`}>Authentication</h3>
                <p>
                    Authentication system is using for verifying user and explore the application. This components have many props as well, (buttonText, actionText, title, handleClick, handleActionClick, loading, actionLoading).
                    button text prop is for button naming, title prop for main title, input and labels are added as children props. ActionText for the action button name.
                </p>
            </div>
            <div className={Style.root_inner}>
                <Auth>
                    <div className="input-wrap pro-mb-5">
                        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Email</label>
                        <input type="text" className="pro-input lg"/>
                    </div>
                    <div className="input-wrap pro-mb-5">
                        <label htmlFor=""  className="pro-font-sm pro-mb-1 pro-fw-medium">Password</label>
                        <input type="password" className="pro-input lg"/>
                    </div>
                </Auth>
            </div>

            <table className='pro-table pro-mt-5'>
                <tr>
                    <th width="40px">Id.</th>
                    <th>Prop Name</th>
                    <th>Type</th>
                    <th>Usage</th>
                </tr>
                <tr>
                    <td>1</td>
                    <td>children</td>
                    <td>@Elem</td>
                    <td>For appending new children inside the component.</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>buttonText</td>
                    <td>@String</td>
                    <td>Main button root name.</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>actionText</td>
                    <td>@String</td>
                    <td>Action button root name.</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>title</td>
                    <td>@String</td>
                    <td>Main title text.</td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>handleClick</td>
                    <td>@Fun</td>
                    <td>Handle main button click.</td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>handleActionClick</td>
                    <td>@Fun</td>
                    <td>Handle action button button click.</td>
                </tr>
                <tr>
                    <td>7</td>
                    <td>loading</td>
                    <td>@bool</td>
                    <td>Handling loading instance of main button.</td>
                </tr>
                <tr>
                    <td>8</td>
                    <td>actionLoading</td>
                    <td>@bool</td>
                    <td>Handling loading instance of action button.</td>
                </tr>
            </table>
        </div>
    )
}



export default React.memo(AuthTab);