import React from "react";
import Style from "./Button.module.scss";
import Highlight from "react-highlight";
import { MdDelete } from "react-icons/md";

const code = `<button className='pro-btn pro-btn-primary'>Primary</button>
<button className='pro-btn pro-btn-secondary'>Primary</button>
<button className='pro-btn pro-btn-outline'>Primary</button>
<button className='pro-btn pro-btn-outline' disabled>Outline</button>
<button className='pro-btn pro-btn-primary loading' disabled>Outline</button>
<button className='pro-btn pro-btn-primary'><span>Button icon</span><MdDelete/></button>
<button className='pro-btn pro-btn-primary lg'>Primary large</button>
<button className='pro-btn pro-btn-secondary lg'>Primary large</button>
<button className='pro-btn pro-btn-outline lg'>Primary large</button>
<a href='/' className='pro-btn pro-btn-primary'>Primary</a>
<a href='/' className='pro-btn pro-btn-secondary'>Primary</a>
<a href='/' className='pro-btn pro-btn-outline'>Primary</a>
<a href='/' className='pro-btn pro-btn-primary full'>Primary</a>
<a href='/' className='pro-btn-link'>Primary</a>
`;

function ButtonTab() {
  return (
    <div className={Style.buttonWrapper}>
      <div className={"text"}>
        <h3 className={`pro-ttl h3 pro-mb-3`}>Button</h3>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi,
          asperiores tenetur animi alias fuga provident deleniti praesentium
          tempore debitis accusamus impedit? Quisquam quas vel aliquam,
          molestias id reprehenderit ipsum neque! Lorem, ipsum dolor sit amet
          consectetur adipisicing elit. Excepturi, asperiores tenetur animi
          alias fuga provident deleniti praesentium tempore debitis accusamus
          impedit? Quisquam quas vel aliquam, molestias id reprehenderit ipsum
          neque!
        </p>
      </div>

      <div className={"component"}>
        <div className={"items"}>
          <button className="pro-btn pro-btn-primary">Primary</button>
        </div>
        <div className={"items"}>
          <button className="pro-btn pro-btn-secondary">Secondary</button>
        </div>
        <div className={"items"}>
          <button className="pro-btn pro-btn-outline">Outline</button>
        </div>
        <div className={"items"}>
          <button className="pro-btn pro-btn-outline " disabled>
            disabled
          </button>
        </div>
        <div className={"items"}>
          <button className="pro-btn pro-btn-primary loading">
            loading Button
          </button>
        </div>
        <div className={"items"}>
          <button className="pro-btn pro-btn-primary">
            <span>Button icon</span>
            <MdDelete />
          </button>
        </div>
      </div>
      <div className={"component"}>
        <div className={"items"}>
          <button className="pro-btn pro-btn-primary lg">span</button>
        </div>
        <div className={"items"}>
          <button className="pro-btn pro-btn-secondary lg">
            Primary large{" "}
          </button>
        </div>
        <div className={"items"}>
          <button className="pro-btn pro-btn-outline lg">Primary large</button>
        </div>
        <div className={"items"}>
          <button className="pro-btn pro-btn-primary lg">
            <span>Primary large icon</span> <MdDelete />
          </button>
        </div>
      </div>

      <div className={"component"}>
        <div className={"items"}>
          <a href="/" className="pro-btn pro-btn-primary">
            Primary
          </a>
        </div>
        <div className={"items"}>
          <a href="/" className="pro-btn pro-btn-secondary">
            Primary
          </a>
        </div>
        <div className={"items"}>
          <a href="/" className="pro-btn pro-btn-outline">
            Primary
          </a>
        </div>
        <div className={"items"}>
          <a href="/" className="pro-btn pro-btn-primary">
            <span>Primary</span>
            <MdDelete />
          </a>
        </div>
      </div>

      <div className={"component"}>
        <div className={"items"} style={{ width: "50%" }}>
          <a href="/" className="pro-btn pro-btn-primary full">
            Primary
          </a>
        </div>
      </div>

      <div className={"component"}>
        <div className={"items"}>
          <a href="/" className="pro-btn-link">
            Primary
          </a>
        </div>
      </div>

      {/* Bootstrap buttons */}
      <div className={"text"}>
        <h3 className={`pro-ttl h3 pro-mb-3`}>Bootstrap Button</h3>
      </div>
      <div className="component">
        <div className="items">
          <button className="pro-btn pro-btn-primary">Primary</button>
        </div>
        <div className="items">
          <button className="pro-btn pro-btn-secondary">Secondary</button>
        </div>
        <div className="items">
          <button className="pro-btn pro-btn-outline">Outline</button>
        </div>
        <div className="items">
          <button className="pro-btn pro-btn-primary pro-btn-lg">
            Large button
          </button>
        </div>
        <div className="items">
          <button className="pro-btn pro-btn-primary" disabled>
            Primary disabled
          </button>
        </div>
        <div className="items">
          <button className="pro-btn pro-btn-secondary" disabled>
            Secondary disabled
          </button>
        </div>
        <div className="items">
          <button className="pro-btn pro-btn-outline" disabled>
            Outline disabled
          </button>
        </div>
        <div className="items">
          <button className="pro-btn pro-btn-primary loading">
            Primary loading
          </button>
        </div>
        <div className="items">
          <button className="pro-btn pro-btn-secondary loading">
            Secondary loading
          </button>
        </div>
        <div className="items">
          <button className="pro-btn pro-btn-outline loading">
            Secondary outline loading
          </button>
        </div>
        <div className="items">
          <button className="pro-btn pro-btn-primary loading" disabled>
            Primary disabled loading
          </button>
        </div>
        <div className="items">
          <button
            className="pro-btn pro-btn-outline loading"
            disabled
          >
            Secondary outline loading
          </button>
        </div>
      </div>

      <div className={"text"}>
          <h3 className={`pro-ttl h3 pro-mb-3`}>Navigation link</h3>
      </div>
      <div className="component">
        <div className="items">
          <div className="nav">
            <div className="nav-link">
              <a href="/" className="pro-btn pro-btn-link">
                Leads
              </a>
            </div>
            <div className="nav-link">
              <a href="/" className="pro-btn pro-btn-link active">
                Leads
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="code-area">
        <Highlight>{code}</Highlight>
      </div>
    </div>
  );
}

export default React.memo(ButtonTab);
