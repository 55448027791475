import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./Assets/scss/app.scss";
import routes from "./routes";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, index) => {
          return (
            <Route path={route.path} element={route.element} key={index}>
              {route?.childRoutes?.map((childRoute, childIndex) => {
                return (
                  <Route
                    path={childRoute.path}
                    element={childRoute.element}
                    key={childIndex}
                    index={childRoute.isIndex}
                  ></Route>
                );
              })}
            </Route>
          );
        })}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
