import React from 'react'
import ActionBtns from '../ActionBtns'
import Style from './bulkEdit.module.scss'
import Select from "react-select";

function BulkEdit({ title, options }) {
  return (
    <div className={Style.root}>
      
      <div className={Style.heading}>
        {title && <h5 class="pro-ttl h5 pro-mb-5">{title}</h5>}
      </div>

      <div className={`${Style.body} pro-mb-2`}>
        <label for="" className="pro-font-sm pro-mb-1 pro-fw-medium">Select a property to update</label>
        <div className={"input-wrap"}>
          <Select
            options={options}
            className={"pro-input lg"}
            classNamePrefix="pro-input"
          />
        </div>
        <div className={"input-wrap pro-mt-3"}>
          <label for="" className="pro-font-sm pro-mb-1 pro-fw-medium">New status</label>
            <Select
              options={options}
              className={"pro-input lg"}
              classNamePrefix="pro-input"
            />
          </div>
      </div>
      <ActionBtns submitText={'create'} cancelText={`cancel`} isRight />

    </div>
  )
}

export default BulkEdit