import React from 'react'
import Style from './ruleTab.module.scss'
import { CreateWidget, HeadingGroup } from '@wac-ui-dashboard/wac_component_library'
import { MdDrafts } from 'react-icons/md'
function RuleTab() {
  return (
    <div className={Style.root}>
      <div className={`col-12 pro-mb-5`}>
        <div className={`text`}>
          <h3 className={`pro-ttl h3 pro-mb-3`}>Create role</h3>
        </div>
      </div>
      <div className={`col-12`}>
        <CreateWidget
          title={`Blank`}
          description={`Start fresh with a blank role template`}
          icon={<MdDrafts size={30} />}
          items={[
            { id: 1, name: 'Admin' },
            { id: 2, name: 'Staff' },
            { id: 3, name: 'Manager' }
          ]}
        />
      </div>


      <div className="col-12">
        <div className="row">
          <div className="col-12 pro-pb-2">
            <HeadingGroup title={`Role Management`}>
              <button className={`pro-btn pro-btn-primary`}>
                Save
              </button>
            </HeadingGroup>
          </div>
          <div className="col-12 pro-pb-5">
            <div className="row">
              <div className="col-4">
                <div className="input-wrap">
                  <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Name</label>
                  <input type="text" placeholder="placeholder" className="pro-input lg"></input>
                </div>
              </div>
              <div className="col-4">
                <div className="input-wrap">
                  <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Staff</label>
                  <input type="text" placeholder="placeholder" className="pro-input lg"></input>
                </div>
              </div>
            </div>
          </div>



          <div className="col-12 pro-pb-5">
            <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
          </div>




          <div className="col-12 pro-pb-5 pro-pt-1">
            <div className="row">
              <div className="col-2">
                <h6 className="pro-ttl h6 pro-mb-0">Members</h6>
              </div>
              <div className="col-10">
                <div className="row">
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check-box1" name="pro-check-box1" className='pro-check' />
                      <label htmlFor="pro-check-box1" className='pro-check-label'>Lorem ipsum</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check-box2" name="pro-check-box2" className='pro-check' defaultChecked />
                      <label htmlFor="pro-check-box2" className='pro-check-label'>Sint occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check-box3" name="pro-check-box3" className='pro-check' />
                      <label htmlFor="pro-check-box3" className='pro-check-label'>Lorem ipsum</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check-box4" name="pro-check-box4" className='pro-check' />
                      <label htmlFor="pro-check-box4" className='pro-check-label'>Sint occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check-box5" name="pro-check-box5" className='pro-check' />
                      <label htmlFor="pro-check-box5" className='pro-check-label'>Excepteur sint </label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check-box6" name="pro-check-box6" className='pro-check' />
                      <label htmlFor="pro-check-box6" className='pro-check-label'>Excepteur occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check-box7" name="pro-check-box7" className='pro-check' />
                      <label htmlFor="pro-check-box7" className='pro-check-label'>Sint occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check-box8" name="pro-check-box8" className='pro-check' />
                      <label htmlFor="pro-check-box8" className='pro-check-label'>Excepteur occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check-box9" name="pro-check-box9" className='pro-check' />
                      <label htmlFor="pro-check-box9" className='pro-check-label'>Excepteur sint occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check-box10" name="pro-check-box10" className='pro-check' defaultChecked />
                      <label htmlFor="pro-check-box10" className='pro-check-label'>Lorem ipsum</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check-box11" name="pro-check-box11" className='pro-check' />
                      <label htmlFor="pro-check-box11" className='pro-check-label'>Excepteur sint occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check-box12" name="pro-check-box12" className='pro-check' />
                      <label htmlFor="pro-check-box12" className='pro-check-label'>Sint occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check-box13" name="pro-check-box13" className='pro-check' />
                      <label htmlFor="pro-check-box13" className='pro-check-label'>Lorem ipsum</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check-box14" name="pro-check-box14" className='pro-check' />
                      <label htmlFor="pro-check-box14" className='pro-check-label'>Excepteur occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check-box15" name="pro-check-box15" className='pro-check' />
                      <label htmlFor="pro-check-box15" className='pro-check-label'>Lorem ipsum</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="col-12 pro-pb-5">
            <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
          </div>


          <div className="col-12 pro-pb-5 pro-pt-1">
            <div className="row">
              <div className="col-2">
                <h6 className="pro-ttl h6 pro-mb-0">Payments</h6>
              </div>
              <div className="col-10">
                <div className="row">
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check1-box1" name="pro-check1-box1" className='pro-check' />
                      <label htmlFor="pro-check1-box1" className='pro-check-label'>Lorem ipsum</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check1-box2" name="pro-check1-box2" className='pro-check' />
                      <label htmlFor="pro-check1-box2" className='pro-check-label'>Sint occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check1-box3" name="pro-check1-box3" className='pro-check' />
                      <label htmlFor="pro-check1-box3" className='pro-check-label'>Lorem ipsum</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check1-box4" name="pro-check1-box4" className='pro-check' />
                      <label htmlFor="pro-check1-box4" className='pro-check-label'>Sint occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check1-box5" name="pro-check1-box5" className='pro-check' />
                      <label htmlFor="pro-check1-box5" className='pro-check-label'>Excepteur sint </label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check1-box6" name="pro-check1-box6" className='pro-check'defaultChecked />
                      <label htmlFor="pro-check1-box6" className='pro-check-label'>Excepteur occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check1-box7" name="pro-check1-box7" className='pro-check' defaultChecked />
                      <label htmlFor="pro-check1-box7" className='pro-check-label'>Sint occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check1-box8" name="pro-check1-box8" className='pro-check' />
                      <label htmlFor="pro-check1-box8" className='pro-check-label'>Excepteur occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check1-box9" name="pro-check1-box9" className='pro-check' />
                      <label htmlFor="pro-check1-box9" className='pro-check-label'>Excepteur sint occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check1-box10" name="pro-check1-box10" className='pro-check' />
                      <label htmlFor="pro-check1-box10" className='pro-check-label'>Lorem ipsum</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check1-box11" name="pro-check1-box11" className='pro-check' />
                      <label htmlFor="pro-check1-box11" className='pro-check-label'>Excepteur sint occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check1-box12" name="pro-check1-box12" className='pro-check' />
                      <label htmlFor="pro-check1-box12" className='pro-check-label'>Sint occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check1-box13" name="pro-check1-box13" className='pro-check' />
                      <label htmlFor="pro-check1-box13" className='pro-check-label'>Lorem ipsum</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check1-box14" name="pro-check1-box14" className='pro-check' />
                      <label htmlFor="pro-check1-box14" className='pro-check-label'>Excepteur occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check1-box15" name="pro-check1-box15" className='pro-check' />
                      <label htmlFor="pro-check1-box15" className='pro-check-label'>Lorem ipsum</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>





          <div className="col-12 pro-pb-5">
            <span style={{ borderBottom: ` 1px solid #F0F0F0`, display: 'block' }}></span>
          </div>




          <div className="col-12 pro-pb-5 pro-pt-1">
            <div className="row">
              <div className="col-2">
                <h6 className="pro-ttl h6 pro-mb-0">Payments</h6>
              </div>
              <div className="col-10">
                <div className="row">
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check2-box1" name="pro-check2-box1" className='pro-check' defaultChecked />
                      <label htmlFor="pro-check2-box1" className='pro-check-label'>Lorem ipsum</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check2-box2" name="pro-check2-box2" className='pro-check'  />
                      <label htmlFor="pro-check2-box2" className='pro-check-label'>Sint occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check2-box3" name="pro-check2-box3" className='pro-check' defaultChecked />
                      <label htmlFor="pro-check2-box3" className='pro-check-label'>Lorem ipsum</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check2-box4" name="pro-check2-box4" className='pro-check' />
                      <label htmlFor="pro-check2-box4" className='pro-check-label'>Sint occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check2-box5" name="pro-check2-box5" className='pro-check' />
                      <label htmlFor="pro-check2-box5" className='pro-check-label'>Excepteur sint </label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check2-box6" name="pro-check2-box6" className='pro-check' />
                      <label htmlFor="pro-check2-box6" className='pro-check-label'>Excepteur occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check2-box7" name="pro-check2-box7" className='pro-check' />
                      <label htmlFor="pro-check2-box7" className='pro-check-label'>Sint occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check2-box8" name="pro-check2-box8" className='pro-check' />
                      <label htmlFor="pro-check2-box8" className='pro-check-label'>Excepteur occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check2-box9" name="pro-check2-box9" className='pro-check' />
                      <label htmlFor="pro-check2-box9" className='pro-check-label'>Excepteur sint occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check2-box10" name="pro-check2-box10" className='pro-check' />
                      <label htmlFor="pro-check2-box10" className='pro-check-label'>Lorem ipsum</label>
                    </div>

                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check2-box11" name="pro-check2-box11" className='pro-check' />
                      <label htmlFor="pro-check2-box11" className='pro-check-label'>Excepteur sint occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check2-box12" name="pro-check2-box12" className='pro-check' />
                      <label htmlFor="pro-check2-box12" className='pro-check-label'>Sint occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check2-box13" name="pro-check2-box13" className='pro-check' />
                      <label htmlFor="pro-check2-box13" className='pro-check-label'>Lorem ipsum</label>
                    </div>

                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check2-box14" name="pro-check2-box14" className='pro-check' />
                      <label htmlFor="pro-check2-box14" className='pro-check-label'>Excepteur occaecat</label>
                    </div>
                  </div>
                  <div className="col-3 pro-py-1">
                    <div className='pro-check-box'>
                      <input type="checkbox" id="pro-check2-box15" name="pro-check2-box15" className='pro-check' defaultChecked />
                      <label htmlFor="pro-check2-box15" className='pro-check-label'>Lorem ipsum</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  )
}

export default RuleTab