import React from 'react'
import ModalLayout from '../../Ui-widgets/ModalLayout'
import { MdCached, MdClose, MdOutlineCloudUpload } from 'react-icons/md'
import SwitchableNav from '../../Ui-widgets/SwitchableNav'
import { IconText,  } from '@wac-ui-dashboard/wac_component_library'
import PaymentHistory from '../../Ui-widgets/PaymentHistory'

function PaymentWidgetTab() {
  return (
    <div>
      <ModalLayout title={`Payment detail`} closeIcon={<MdClose size={20} />}>

        <SwitchableNav />

        <div className={`pro-w-100  pro-p-5`}>

          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">package</label>
            <input
              type="text"
              // placeholder="placeholder"
              className="pro-input lg"
            />
          </div>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Mode of Payment</label>
            <input
              type="text"
              // placeholder="placeholder"
              className="pro-input lg"
            />
          </div>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Amount</label>
            <input
              type="text"
              // placeholder="placeholder"
              className="pro-input lg"
            />
          </div>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Proof of Payment</label>
            <div className="input-drag">
              <input
                type="file"
                placeholder="placeholder"
                className="pro-input"
              />
              <span className="input-drag-box">
                <IconText icon={<MdOutlineCloudUpload size={16}/>} title={`Drop files to attach or browse`}/>
              </span>
            </div>
          </div>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Add-on</label>
            <input
              type="text"
              // placeholder="placeholder"
              className="pro-input lg"
            />
          </div>
          <div className="input-wrap pro-mb-0">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Note</label>
            <input
              type="text"
              // placeholder="placeholder"
              className="pro-input lg"
            />
          </div>


          <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
            <button className="pro-btn-link lg pro-px-5"><span>cancel</span></button>
              <button className="pro-btn pro-btn-primary lg"><span>Create</span></button>
          </div>
        </div>


        <div className={`pro-w-100  pro-p-5`}>
            <PaymentHistory icon={<MdCached size={15}/>}/>
            <PaymentHistory icon={<MdCached size={15}/>}/>
            <PaymentHistory icon={<MdCached size={15}/>}/>
        </div>





      </ModalLayout>
    </div>
  )
}

export default PaymentWidgetTab