import React from "react";
import Style from "./BootsrapFormTab.module.scss";
import Highlight from "react-highlight";
import { CgSearch } from "react-icons/cg";

const code = `
<div className="pro-check-box">
  <input type="checkbox" className="pro-check" id="checkbox" name="pro-checkbox" />
  <label htmlFor="checkbox" className="pro-check-label"> Checkbox </label>
</div>
<div className="pro-check-box">
  <input type="radio" className="pro-check" id="radio" name="pro-radio" />
  <label htmlFor="radio" className="pro-check-label"> Radio </label>
</div>
`;

function BootsrapFormTab() {
  return (
    <div className={Style.buttonWrapper}>
      <div className={"text"}>
        <h3 className={`pro-ttl h3 pro-mb-3`}>Bootsrap Forms</h3>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi,
          asperiores tenetur animi alias fuga provident deleniti praesentium
          tempore debitis accusamus impedit? Quisquam quas vel aliquam,
          molestias id reprehenderit ipsum neque! Lorem, ipsum dolor sit amet
          consectetur adipisicing elit. Excepturi, asperiores tenetur animi
          alias fuga provident deleniti praesentium tempore debitis accusamus
          impedit? Quisquam quas vel aliquam, molestias id reprehenderit ipsum
          neque!
        </p>
      </div>

      <div className={"component input"}>
        <div className="items">
          <div className="form-group ">
            <input
              type="text"
              className="form-control"
              placeholder="Placeholder"
            />
          </div>
        </div>
        <div className="items">
          <div className="form-group ">
            <input
              type="text"
              className="form-control is-invalid"
              placeholder="Placeholder"
            />
            <div className="invalid-feedback">This field is required.</div>
          </div>
        </div>
        <div className="items">
          <div className="form-group ">
            <input
              type="text"
              className="form-control"
              placeholder="Placeholder"
              disabled
            />
          </div>
        </div>
        <div className="items">
          <div className="form-group ">
            <input
              type="text"
              className="form-control form-control-lg"
              placeholder="Placeholder"
            />
          </div>
        </div>
      </div>
      <div className={"component input"}>
        <div className="items">
          <div className="input-group mb-3">
            <input
              type="text"
              placeholder="Username"
              className="form-control icon-l"
            />
            <span className="input-group-text">
              <CgSearch size={15} />
            </span>
          </div>
        </div>
        <div className="items">
          <div className="input-group mb-3">
            <input
              type="text"
              placeholder="Username"
              className="form-control icon-r"
            />
            <span className="input-group-text">
              <CgSearch size={15} />
            </span>
          </div>
        </div>
        <div className="items">
          <div className="input-group mb-3">
            <input
              type="text"
              placeholder="Username"
              className="form-control form-control-lg icon-l"
            />
            <span className="input-group-text">
              <CgSearch size={16} />
            </span>
          </div>
        </div>
        <div className="items">
          <div className="input-group mb-3">
            <input
              type="text"
              placeholder="Username"
              className="form-control form-control-lg icon-r"
            />
            <span className="input-group-text">
              <CgSearch size={16} />
            </span>
          </div>
        </div>
        <div className="items">
          <div className="input-group mb-3">
            <input
              type="text"
              placeholder="Username"
              className="form-control icon-l"
              disabled
            />
            <span className="input-group-text">
              <CgSearch size={15} />
            </span>
          </div>
        </div>
        <div className="items">
          <div className="input-group mb-3">
            <input
              type="text"
              placeholder="Username"
              className="form-control icon-r"
              disabled
            />
            <span className="input-group-text">
              <CgSearch size={15} />
            </span>
          </div>
        </div>
      </div>
      <div className="component input">
        <div className="items">
          <div className="form-group ">
            <select className="form-select" aria-label="Default select example" defaultValue="0">
              <option value="0">Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
        </div>
        <div className="items">
          <div className="form-group ">
            <select
              className="form-select is-invalid"
              aria-label="Default select example"
              defaultValue="0"
            >
              <option value="0">Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
            <div className="invalid-feedback">This field is required.</div>
          </div>
        </div>
        <div className="items">
          <div className="form-group ">
            <select
              className="form-select"
              aria-label="Default select example"
              disabled
              defaultValue={0}
            >
              <option value={0}>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
        </div>
        <div className="items">
          <div className="form-group ">
            <select
              className="form-select form-select-lg"
              aria-label="Default select example"
              defaultValue={0}
            >
              <option value={0}>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
        </div>
      </div>

      <div className="component input">
        <div className="items">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              Default checkbox
            </label>
          </div>
        </div>
        <div className="items">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              defaultChecked
            />
            <label className="form-check-label" htmlFor="flexCheckChecked">
              Checked checkbox
            </label>
          </div>
        </div>
        <div className="items">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDisabled"
              disabled
            />
            <label className="form-check-label" htmlFor="flexCheckDisabled">
              Disabled checkbox
            </label>
          </div>
        </div>
        <div className="items">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDisabled"
              disabled
              defaultChecked
            />
            <label className="form-check-label" htmlFor="flexCheckDisabled">
              Disabled checkbox
            </label>
          </div>
        </div>
      </div>
      <div className="component input">
        <div className="items">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
            />
            <label className="form-check-label" htmlFor="flexRadioDefault1">
              Default radio
            </label>
          </div>
        </div>
        <div className="items">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              defaultChecked
            />
            <label className="form-check-label" htmlFor="flexRadioDefault2">
              Default checked radio
            </label>
          </div>
        </div>
        <div className="items">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDisabled"
              id="flexRadioDisabled"
              disabled
            />
            <label className="form-check-label" htmlFor="flexRadioDisabled">
              Disabled radio
            </label>
          </div>
        </div>
        <div className="items">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDisabled"
              id="flexRadioDisabled"
              disabled
              defaultChecked
            />
            <label className="form-check-label" htmlFor="flexRadioDisabled">
              Disabled radio
            </label>
          </div>
        </div>
      </div>

      <div className="code-area">
        <Highlight>{code}</Highlight>
      </div>
    </div>
  );
}

export default React.memo(BootsrapFormTab);
