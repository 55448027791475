import { Image } from '@wac-ui-dashboard/wac_component_library'
import React from 'react'
import Style from './userCard.module.scss'
function UserCard({ src, width, height, alt, title, subtitle, ViewIcon, ViewCount, graphIcon, GraphPercentage, ActiveTick }) {
  return (
    <div className={`${Style.root}`}>

        <div className={`${Style.root_inner}`}>
            <div className={`${Style.root_image}`}>
                <Image src={src} width={width || 100} height={height || 100} alt={alt || `user image`}/>
            </div>

            <div className={`${Style.root_text_wrapper}`}>
                <div className={`${Style.root_text} pro-mb-2 pro-px-1 pro-pt-2`}>
                    <p className='pro-fw-bolder pro-mb-0'>{title || `add title`}</p>
                    <p className='pro-fw-regular pro-mb-0'>{subtitle || `add sub title`}</p>
                </div>

                <div className={`${Style.review} pro-px-2 pro-mb-1 pro-mt-auto `}>
                    <ul className={`pro-d-flex pro-flex-wrap`}>
                        {ViewIcon && ViewCount && 
                        <li className={`pro-d-flex pro-items-center pro-badge badge-grey-outline pro-px-1 pro-py-0 pro-my-1`}>
                            <span>{ViewIcon}</span>
                            <span className='pro-fw-medium pro-font-xs pro-mb'>{ViewCount}</span>
                        </li>}
                        {graphIcon && GraphPercentage && 
                        <li className={`pro-d-flex pro-items-center pro-badge badge-grey-outline pro-px-1 pro-py-0 pro-my-1`}>
                            <span>{graphIcon}</span>
                            <span className='pro-fw-medium pro-font-xs pro-mb'>{GraphPercentage}</span>
                        </li>}
                    </ul>
                </div>

                {ActiveTick && <div className={`pro-btn rounded-tick pro-p-0`}>
                    {ActiveTick}
                </div>}
            </div>
        </div>

    </div>
  )
}

export default UserCard