import React from "react";
import MediaActions from "../../Ui-widgets/MediaActions";
import Style from "./shareTab.module.scss";
const data = [
  {id: 1, icon : <span className="material-symbols-outlined"> ios_share </span> , title : 'Share'},
  {id: 2, icon : <span className="material-symbols-outlined"> print </span> , title : 'Print'},
  {id: 3, icon : <span className="material-symbols-outlined"> cloud_download </span> , title : 'Download'}
  
]
const ShareTab = () => {
  return (
    <div className={Style.root}>
        <MediaActions extraClassName={`pro-mt-1`} data={data}/>
    </div>
  );
};

export default React.memo(ShareTab);
