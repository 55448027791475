import React, { useState } from "react";
import Style from "./MenuDrawer.module.scss";
import Assets from "../../Assets/Assets";
import { useMemo } from "react";

const MenuDrawer = ({ menus, type, icon, NavLink, Nav, goBackIcon, goBackTittle, isGoBack }) => {
  const [expanded, setExpanded] = useState(type === "type-2" ? true : false);

  const setType = useMemo(
    () => (type === "type-2" ? Style.type_2 : Style.type_1),
    [type]
  );
  const checkExpand = useMemo(
    () => (expanded ? Style.expanded : ``),
    [expanded]
  );

  return (
    <div className={`${Style.proDrawer} secondary ${checkExpand} ${setType}`}>
      {isGoBack && <div className={`${Style.goBack} pro-w-100 pro-d-flex pro-items-center pro-pb-4 pro-mb-4  `}>
          <div className={`${Style.goBackIcon} `}>
              {goBackIcon || `icon`}
          </div>
          {goBackTittle && <p className="pro-fw-bold pro-mb-0  pro-text-nowrap pro-overflow-hidden">{goBackTittle}</p>}
      </div>}
      {type === "type-2" && (
        <button
          onClick={() => setExpanded(!expanded)}
          className={`
            pro-btn 
            pro-btn-outline 
            pro-rounded-circle 
            ${Style.expandButton}`}
        >
          {icon && icon}
        </button>
      )}

      {NavLink && Nav && (
        <Nav
          variant="pills"
          className={`
          pro-flex-column 
          pro-overflow-hidden 
          ${Style.drawerNav}`}
        >
          {menus &&
            menus.map((menu, index) => {
              return (
                <Nav.Item key={index}>
                  <NavLink
                    end
                    to={menu.link}
                    className="
                    pro-d-flex
                    pro-items-center
                    pro-mb-1
                    nav-link"
                  >
                    {menu.icon ? (
                      menu.icon
                    ) : (
                      <img src={Assets[menu.iconAsset]} alt=""></img>
                    )}
                    <p
                      className="
                    pro-mb-0
                    pro-text-nowrap
                    pro-overflow-hidden"
                    >
                      {menu.title}
                    </p>
                  </NavLink>
                </Nav.Item>
              );
            })}
        </Nav>
      )}
    </div>
  );
};

export default MenuDrawer;
