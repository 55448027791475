import React from "react";
import Layout from "../../Ui-components/Layout";
import MenuDrawer from "../../Ui-components/MenuDrawer";
import { Outlet } from "react-router-dom";

const TasksPage = () => {
  const drawerMenu = [
    { title: "Task 1", link: "/tasks", iconAsset: "DrawerIcon1" },
    { title: "Task 2", link: "/tasks/task2", iconAsset: "DrawerIcon2" },
    { title: "Task 3", link: "/tasks/task3", iconAsset: "DrawerIcon3" },
  ];
  return (
    <Layout>
      <div className="pro-d-flex">
        <MenuDrawer menus={drawerMenu}></MenuDrawer>
        <div className="pro-page-wrp">
          <div className="pro-container-inner">
            <Outlet></Outlet>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TasksPage;
