import React from "react";
import Style from "./TableTab.module.scss";
import Highlight from "react-highlight";
import Table from "../../Ui-widgets/Table";
import SearchFilters from "../../Ui-widgets/SearchFilters";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const code = `const tableData = {
  columns: [
    { name: "Deal Name", field: "dealName", sortable: true },
    { name: "Service", field: "service", sortable: true },
    { name: "Client", field: "client", sortable: true },
    { name: "Deal Size", field: "dealSize", sortable: true },
    { name: "Expected Close ", field: "expectedClose", sortable: true },
    { name: "Notes", field: "notes", sortable: true },
    { name: "Stage", field: "stage", sortable: true },
  ],
  rows: [
    { dealName: "Trikart App", service: "Ecommerce", client: "James", dealSize: "$32,000", expectedClose: "17/3/2022", notes: "Lorem ipsum dolor sit amet, consectetur", stage: "Pending"}
  ]
}
<Table checkboxSelection rows={tableData.rows} columns={tableData.columns}></Table>
`;

const tableData = {
  columns: [
    { name: "Deal Name", field: "dealName", sortable: true },
    { name: "Service", field: "service", sortable: true },
    { name: "Client", field: "client", sortable: true },
    { name: "Deal Size", field: "dealSize", sortable: true },
    { name: "Expected Close ", field: "expectedClose", sortable: true },
    { name: "Notes", field: "notes", sortable: true },
    { name: "Stage", field: "stage", sortable: true },
  ],
  rows: [
    {
      dealName: "Trikart App",
      service: "Ecommerce",
      client: "James",
      dealSize: "$32,000",
      expectedClose: "17/3/2022",
      notes: "Lorem ipsum dolor sit amet, consectetur",
      stage:  "Pending" ,
    },
    {
      dealName: "Acme Corp",
      service: "Dashboard",
      client: "Charles",
      dealSize: "$10,000",
      expectedClose: "17/3/2022",
      notes: "Nemo enim ipsam voluptatem",
      stage: "Pending",
    },
    {
      dealName: "Al Qathami",
      service: "Landing page",
      client: "James",
      dealSize: "$15,000",
      expectedClose: "17/3/2022",
      notes: "Quis autem vel eum iure reprehenderi",
      stage: "Pending",
    },
    {
      dealName: "Landor",
      service: "Flutter App",
      client: "James",
      dealSize: "$2,500",
      expectedClose: "17/3/2022",
      notes: "Autem reprehenderit",
      stage: "Pending",
    },
    {
      dealName: "Trikart App",
      service: "Ecommerce",
      client: "James",
      dealSize: "$4,000",
      expectedClose: "17/3/2022",
      notes: "Quis autem veleum reprehenderit",
      stage: "Pending",
    },
    {
      dealName: "Marriott",
      service: "Dashboard",
      client: "Charles",
      dealSize: "$7,000",
      expectedClose: "17/3/2022",
      notes: "Duis aute irure dolor",
      stage: "Pending",
    },
    {
      dealName: "Al Qathami",
      service: "Landing page",
      client: "James",
      dealSize: "$6,000",
      expectedClose: "17/3/2022",
      notes: "Neque porro quisquam",
      stage: "Pending",
    },
    {
      dealName: "Landor",
      service: "Flutter App",
      client: "James",
      dealSize: "$12,000",
      expectedClose: "17/3/2022",
      notes: "Excepteur sint occaecat cupidatat",
      stage: "Pending",
    },
    {
      dealName: "Trikart App",
      service: "Ecommerce",
      client: "James",
      dealSize: "$32,000",
      expectedClose: "17/3/2022",
      notes: "Lorem ipsum dolor sit amet, consectetur",
      stage: "Pending",
    },
    {
      dealName: "Marriott",
      service: "Dashboard",
      client: "Charles",
      dealSize: "$7,000",
      expectedClose: "17/3/2022",
      notes: "Duis aute irure dolor",
      stage: "Pending",
    },
    {
      dealName: "Al Qathami",
      service: "Landing page",
      client: "James",
      dealSize: "$6,000",
      expectedClose: "17/3/2022",
      notes: "Neque porro quisquam",
      stage: "Pending",
    },
    {
      dealName: "Landor",
      service: "Flutter App",
      client: "James",
      dealSize: "$12,000",
      expectedClose: "17/3/2022",
      notes: "Excepteur sint occaecat cupidatat",
      stage: "Pending",
    },
  ],
};
const enabledFilters = [
  { type: "search" },
  {
    type: "buttonGroup",
    fields: [
      { label: "All", count: 50 },
      { label: "Pending", count: 4 },
      { label: "Contacted", count: 10 },
      { label: "Proposal sent", count: 10 },
      { label: "No Response", count: 10 },
      { label: "Lost", count: 10 },
      { label: "Won", count: 10 },
    ],
  },
  { type: "date" },
  { type: "filters" },
  { type: "actions" },
];

const TableTab = () => {
  return (
    <div className={Style.buttonWrapper}>
      <div className={"text bg-primary"}>
        <h3 className={`pro-ttl h3 pro-mb-3`}>Table</h3>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi,
          asperiores tenetur animi alias fuga provident deleniti praesentium
          tempore debitis accusamus impedit? Quisquam quas vel aliquam,
          molestias id reprehenderit ipsum neque! Lorem, ipsum dolor sit amet
          consectetur adipisicing elit. Excepturi, asperiores tenetur animi
          alias fuga provident deleniti praesentium tempore debitis accusamus
          impedit? Quisquam quas vel aliquam, molestias id reprehenderit ipsum
          neque!
        </p>
      </div>

      <div className={"component"}>
        <SearchFilters filters={enabledFilters}></SearchFilters>
        <Table
          checkboxSelection
          rows={tableData.rows}
          columns={tableData.columns}
        ></Table>
        <div className="pro-flex-grow-1 pro-py-4 pro-d-flex pro-justify-center">
          <nav
            aria-label="Page navigation example pro-pagination"
            className="pro-mt-2"
          >
            <ul className="pro-pagination">
              <li className="page-item disabled">
                <a className="page-link" href="/" aria-label="Previous">
                  <span aria-hidden="true">
                    <FaChevronLeft size={13} />
                  </span>
                </a>
              </li>
              <li className="page-item active">
                <a className="page-link" href="/">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="/">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="/">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="/">
                  4
                </a>
              </li>
              <li className="page-item">
                <span className="page-link" href="/">
                  ...
                </span>
              </li>
              <li className="page-item">
                <a className="page-link" href="/">
                  10
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="/" aria-label="Next">
                  <span aria-hidden="true">
                    <FaChevronRight size={13} />
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="code-area">
        <Highlight>{code}</Highlight>
      </div>
    </div>
  );
};

export default React.memo(TableTab);
