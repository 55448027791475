import React from "react";
import Style from "./TypographyTab.module.scss";
import Highlight from "react-highlight";

const code = `<h1 className="pro-ttl h1">{Text content}</h1>
<h2 className="pro-ttl h2">{Text content}</h2>
<h3 className="pro-ttl h3">{Text content}</h3>
<h4 className="pro-ttl h4">{Text content}</h4>
<h5 className="pro-ttl h5">{Text content}</h5>
<h6 className="pro-ttl h6">{Text content}</h6>
<p className="pro-font-bolder">{Text content}</p>
<p className="pro-font-bold">{Text content}</p>
<p className="pro-font-medium">{Text content}</p>
<p className="pro-font-regular">{Text content}</p>
<p className="pro-font-light">{Text content}</p>
<p className="pro-font-sm">{Text content}</p>
<p className="pro-font-xs">{Text content}</p>
`;

const TypographyTab = () => {
  return (
    <div className={Style.buttonWrapper}>
      <div className={"text bg-primary"}>
        <h3 className={`pro-ttl h3 pro-mb-3`}>Typography</h3>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi,
          asperiores tenetur animi alias fuga provident deleniti praesentium
          tempore debitis accusamus impedit? Quisquam quas vel aliquam,
          molestias id reprehenderit ipsum neque! Lorem, ipsum dolor sit amet
          consectetur adipisicing elit. Excepturi, asperiores tenetur animi
          alias fuga provident deleniti praesentium tempore debitis accusamus
          impedit? Quisquam quas vel aliquam, molestias id reprehenderit ipsum
          neque!
        </p>
      </div>

      <div className={"component input"}>
        <div className={"items"}>H1</div>
        <div className="">
          <h1 className="pro-ttl h1 pro-mb-0">Heading 1</h1>
        </div>
      </div>
      <div className={"component input"}>
        <div className={"items"}>H2</div>
        <div className="">
          <h2 className="pro-ttl h2 pro-mb-0">Heading 2</h2>
        </div>
      </div>
      <div className={"component input"}>
        <div className={"items"}>H3</div>
        <div className="">
          <h3 className="pro-ttl h3 pro-mb-0">Heading 3</h3>
        </div>
      </div>
      <div className={"component input"}>
        <div className={"items"}>H4</div>
        <div className="">
          <h4 className="pro-ttl h4 pro-mb-0">Heading 4</h4>
        </div>
      </div>
      <div className={"component input"}>
        <div className={"items"}>H5</div>
        <div className="">
          <h5 className="pro-ttl h5 pro-mb-0">Heading 5</h5>
        </div>
      </div>
      <div className={"component input"}>
        <div className={"items"}>H6</div>
        <div className="">
          <h6 className="pro-ttl h6 pro-mb-0">Heading 6</h6>
        </div>
      </div>

      <div className={"component input"}>
        <div className={"items"}>Font weight bolder</div>
        <div className="">
          <p className="pro-fw-bolder pro-mb-0">Lorem, ipsum</p>
        </div>
      </div>
      <div className={"component input"}>
        <div className={"items"}>Font weight bold</div>
        <div className="">
          <p className="pro-fw-bold pro-mb-0">Lorem, ipsum</p>
        </div>
      </div>
      <div className={"component input"}>
        <div className={"items"}>Font weight medium</div>
        <div className="">
          <p className="pro-fw-medium pro-mb-0">Lorem, ipsum</p>
        </div>
      </div>
      <div className={"component input"}>
        <div className={"items"}>Font weight regular</div>
        <div className="">
          <p className="pro-fw-regular pro-mb-0">Lorem, ipsum</p>
        </div>
      </div>
      <div className={"component input"}>
        <div className={"items"}>Font weight light</div>
        <div className="">
          <p className="pro-fw-light pro-mb-0">Lorem, ipsum</p>
        </div>
      </div>
      <div className={"component input"}>
        <div className={"items"}>Normal text font size (14px)</div>
        <div className="">
          <p className="pro-mb-0">Lorem, ipsum</p>
        </div>
      </div>
      <div className={"component input"}>
        <div className={"items"}>Small text font size (13px)</div>
        <div className="">
          <p className="pro-font-sm pro-mb-0">Lorem, ipsum</p>
        </div>
      </div>
      <div className={"component input"}>
        <div className={"items"}>Smaller text font size (12px)</div>
        <div className="">
          <p className="pro-font-xs pro-mb-0">Lorem, ipsum</p>
        </div>
      </div>

      <div className="code-area">
        <Highlight>{code}</Highlight>
      </div>
    </div>
  );
};

export default React.memo(TypographyTab);
