import React from 'react'
import Style from './tabHeading.module.scss'
function TabHeading({ data, handleClose, handClick, closeIcon }) {
    return (
        <div className={`${Style.root} pro-px-5`}>
            <ul className={`pro-d-flex pro-items-center`}>
                {data && data.map((item) => {
                    return (
                        <li key={item.id} className={item.id === 1 ? Style.active : ''}>
                            <button
                                className={`pro-btn pro-btn-outline pro-items-center`}
                                onClick={() => handClick()}>
                                <span
                                    className={`pro-d-flex pro-items-center`}>
                                    <div className="pro-avatar">
                                        <img
                                            src={item.image}
                                            alt="" />
                                    </div>
                                    <p
                                        className="pro-fw-medium pro-mb-0 pro-ps-3">
                                        {item.userName || 'user'}
                                    </p>
                                </span>
                                <span>
                                    <span
                                        className={`pro-btn border-rounded pro-p-1 pro-ms-2`}
                                        onClick={() => handleClose()}>
                                        {closeIcon || 'x'}
                                    </span>
                                </span>
                            </button>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default TabHeading