 import React from 'react'
import context from '../../Ui-components/context'
import OrderColumn from '../../Ui-widgets/OrderColumn'
 import Style from './orderColumnTab.module.scss'
 function OrderColumnTab() {
   return (
     <div className={Style.root}>
        <OrderColumn title={`Choose which columns you see`} data={context.datachoose}/>
     </div>
   )
 }
 
 export default OrderColumnTab