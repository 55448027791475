import { MdOutlineHeadsetMic, MdOutlineChat  } from "react-icons/md";
export const card_widget_01 = [
    {id : 1, icon :<MdOutlineHeadsetMic size={16}/>, title : 'Initiation - Vijayan - Marriot ', time : '12:30PM - 1:30PM'},
    {id : 2, icon :<MdOutlineChat size={16}/>, title : 'Follow up meeting - Kevin - Trikart ', time : '12:30PM - 1:30PM'},
    {id : 3, icon :<MdOutlineHeadsetMic size={16}/>, title : 'Initiation meeting - Kevin - Trikart  ', time : '12:30PM - 1:30PM'},
];

export const card_widget_02 = [
    {id : 1,  title : 'Send Invoice - Marriot dashboard', priority: false},
    {id : 2,  title : 'Follow up -  Trikart invoice', priority: true},
    {id : 3,  title : 'Send Proposal - Ikea flutter app', priority: false},
    {id : 4,  title : 'Follow up -   Lulu Exchange', priority: false},
    {id : 5,  title : 'Send Invoice - Marriot dashboard', priority: false},
    {id : 6,  title : 'Follow up -  Trikart invoice', priority: true},
    {id : 7,  title : 'Send Proposal - Ikea flutter app', priority: false},
];