import React from 'react'
import ActionBtns from '../ActionBtns'
import Style from './orderColumn.module.scss'
function OrderColumn({title, data}) {
  return (
    <div  className={Style.root}>
        <div className={Style.heading}>
            {title && <h5 class="pro-ttl h5 pro-mb-5">{title}</h5>}
        </div>
        <div className={Style.body}>
            <div className={Style.items}>
                {data && data.map(item => {
                    return(
                        <div className={Style.item}>
                            <div className={Style.icon}></div>
                            <div className="pro-check-box">
                                <input type="checkbox" className="pro-check" id={`${item.title}-${item.id}`} name="pro-checkbox" />
                                <label htmlFor={`${item.title}-${item.id}`} className="pro-check-label pro-fw-bold"> {item.title} </label>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
        <ActionBtns submitText={'create'} cancelText={`cancel`} isRight />
    </div>
  )
}

export default OrderColumn