import { Header, SimpleArticle } from '@wac-ui-dashboard/wac_component_library'
import React from 'react'
import Style from './profilePageOverview.module.scss'
import Assets from '../../Assets/Assets'
import { navigations, profilesData, profilesImageSlider, tableData01, navigationsNew, tableData02, tableData03, tableData04 } from './contexts'
import TabHeading from '../../Ui-widgets/TabHeading'
import {MdClose,  MdEdit, MdOutlineAutoGraph, MdOutlineVisibility, MdOutlineShoppingBag } from 'react-icons/md'
import { FaRegEdit } from 'react-icons/fa'
import ProfileSideBar from '../../Ui-widgets/ProfileSideBar'
import SimpleImageSlider from '../../Ui-widgets/SimpleImageSlider'
import SimpleTextDetails from '../../Ui-widgets/SimpleTextDetails'
import NavGroup from '../../Ui-widgets/NavGroup'
import MediaActions from '../../Ui-widgets/MediaActions'
import MultiColumnTable from '../../Ui-widgets/MultiColumnTable'
import DataContainer from '../../Ui-widgets/DataContainer'
import UserCard from '../../Ui-widgets/UserCard'
import UserCardWrapper from '../../Ui-widgets/UserCardWrapper'
import MaterialIcon from 'material-icons-react'
import WarningBox from '../../Ui-widgets/WarningBox'


function ProfilePageOverview() {
  return (
    <div className={ Style.root}>
        <Header logo={Assets.LOGO} navigations={navigations}/>
        <main className={`pro-w-100`}>
          <TabHeading data={profilesData} closeIcon={ <MdClose/>}/>
         
          <div className={`pro-d-flex`}>


            <ProfileSideBar extraClassName={'wrapper'}>

              <div className={`pro-px-1`}>
                <div className={`pro-p-5`}>
                  <SimpleImageSlider data={profilesImageSlider} width={329.7} height={329.55}/>
                  <SimpleTextDetails 
                    extraClassNames={`pro-mt-5 pro-mb-5`}
                    designation={`Engineer`}
                    isOnline={true}
                    title={`Nithin Varghese (28)`}
                    uId={`CUID-854435`}
                    lastOnline={`Last online: 1 Day ago`}/>
                    <MediaActions extraClassName={`pro-mt-1 pro-mb-5`}/>

                    <WarningBox percentage={90} button={{name: 'button'}} title={`Profile Incomplete`} type={`type-2`}/>

                </div>
              </div>


           
              <hr />
              <div className={`pro-px-1`}>
                <div className={`pro-p-5`}>
                  <MultiColumnTable
                    minWidth={`50%`} 
                    editIcon={<FaRegEdit/>}
                    title={`Basic Details`}
                    data={tableData01}/>
                </div>
              </div>


            </ProfileSideBar>




            <div className={`pro-w-100 pro-d-flex pro-flex-column`}>


              <div className={`pro-w-100 pro-light wrapper-next pro-pt-3`}>
                <NavGroup navigations={navigationsNew} />
              </div>
              
              <div className={`pro-p-5 wrapper-next-inner`}>

                <div className={`col-12 pro-mb-4`}>
                  <div className={`row gx-2`}>
                    <div className="pro-btn-group col-auto">
                      <button className="pro-btn pro-btn-outline lg active">Viewed by user</button>
                      <button className="pro-btn pro-btn-outline lg">Viewed by others</button>
                    </div>
                    <div class="col-auto">
                      <button class="pro-btn pro-btn-outline lg">
                        <span class="material-symbols-outlined"> tune </span>
                        <span>Filters</span>
                      </button>
                    </div>

                  </div>
                </div>
                <DataContainer>
                  <WarningBox button={{name: 'button'}} title={`Profile Incomplete`}/>
                </DataContainer>
                <div className='pro-p-4'></div>
                <DataContainer>

                  <div className={`pro-pb-5 pro-w-100`}>
                    <SimpleArticle title={'About'} description={`Nithin is my son and his education qualification is M-tech and his job Status is Europe Business analyst. We are Residing at Thrissur District , Kerala.`}/>
                  </div>



                  <hr className={`pro-my-2`} />
                  <div className={`pro-pt-5 pro-w-100`}>
                    <MultiColumnTable
                      minWidth={`300px`} 
                      editIcon={<MaterialIcon icon="edit_square" />}
                      title={`Education Details`}
                      data={tableData02}/>
                  </div>



                  <hr className={`pro-my-2`} />
                  <div className={`pro-pt-5 pro-w-100`}>
                    <MultiColumnTable
                      minWidth={`300px`} 
                      editIcon={<MdEdit/>}
                      title={`Job Details`}
                      data={tableData03}/>
                  </div>
                  
                  <hr className={`pro-my-2`} />

                  <div className={`pro-pt-5 pro-w-100`}>
                    <MultiColumnTable
                      minWidth={`300px`} 
                      editIcon={<MdOutlineShoppingBag fontWeight={600 }/>}
                      title={`Family Details`}
                      data={tableData04}/>
                  </div>

                  <UserCardWrapper>
                    <UserCard 
                      src={Assets.user_image}
                      height={170}
                      width={170}
                      title={`Janice (24)`}
                      subtitle={`Engineer`}
                      ViewIcon={<MdOutlineVisibility size={14}/>}
                      ViewCount={2}
                      graphIcon={<MdOutlineAutoGraph size={14}/>}
                      GraphPercentage={`50%`}/>
                  </UserCardWrapper>

                </DataContainer>
              </div>
            </div>
          </div>
        </main>
    </div>
  )
}

export default ProfilePageOverview