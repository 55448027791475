import React from "react";
import Style from "./ThemingTab.module.scss";
import Highlight from "react-highlight";

const code = `//variables.scss
// Light theme color variables
$pro-primary-light: #0052cc;
$pro-primary-hover-light:#005ae0;
$pro-primary-active-light:#0747a6;
$pro-secondary-light: #f4f5f7;
$pro-secondary-hover-light:#f0f1f3;
$pro-secondary-active-light:#ebecf0;
$pro-success-light: #117872;
$pro-danger-light: #b32318;
$pro-light-light: #fff;
$pro-dark-light: #000;
$pro-outline-light: #fff;
$pro-outline-hover-light: #f8f9fb;
$pro-outline-active-light: #f4f5f7;
$pro-outline-border-light:#dfe3eb;
$pro-disable-text-light: #a5adba;
$pro-disable-bg-light: #f5f6f7;
$pro-input-text-light: #4c4c4c;
$pro-input-hover-bg-light: #fafbfc;
$pro-input-focus-shadow-light: #4c9aff;
$pro-input-active-light: #7a869a;
$pro-avatar-remaining-bg-light: #dfe1e6;
$pro-nav-btn-link-hover-light: #deebff;
// Dark theme color variables
$pro-primary-dark: #0052cc;
$pro-primary-hover-dark:#005ae0;
$pro-primary-active-dark:#0747a6;
$pro-secondary-dark: #4e4e4e;
$pro-secondary-hover-dark:#f0f1f3;
$pro-secondary-active-dark:#ebecf0;
$pro-success-dark: #117872;
$pro-danger-dark: #b32318;
$pro-light-dark: #000;
$pro-dark-dark: #fff;
$pro-outline-dark: #000;
$pro-outline-hover-dark: #f8f9fb;
$pro-outline-active-dark: #f4f5f7;
$pro-outline-border-dark:#dfe3eb;
$pro-disable-text-dark: #a5adba;
$pro-disable-bg-dark: #f5f6f7;
$pro-input-text-dark: #ebeaea;
$pro-input-hover-bg-dark: #0a0a0a;
$pro-input-focus-shadow-dark: #4c9aff;
$pro-input-active-dark: #7a869a;
$pro-avatar-remaining-bg-dark: #dfe1e6;
$pro-nav-btn-link-hover-dark: #deebff;`;

const ThemingTab = () => {
  return (
    <div className={Style.buttonWrapper}>
      <div className={"text bg-primary"}>
        <h3 className={`pro-ttl h3 pro-mb-3`}>Color Code and variables</h3>
        <p>
          Color system is fully adjustable and ready to change. We have listed all the colors and variables as per the ../variable.scss file.
          Change the values as per the theme. The dark mode is also listed scss variables are comes with light and dark mode.
          Whenever new theme need you can add new variable with the slug ( for dark mode -dark nad light mode -light)
        </p>
      </div>

      <div className={"component"}>
          <table className="pro-table">
            <thead>
              <tr>
                <th>Css variable</th>
                <th>Scss variable for light theme</th>
                <th>Scss variable for dark theme</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>--pro-primary</td>
                <td><span className={`${Style.colorIcon} pro-primary-light`} ></span>$pro-primary-light</td>
                <td><span className={`${Style.colorIcon} pro-primary-dark`} ></span>$pro-primary-dark</td>
                <td>Primary color</td>
              </tr>
              <tr>
                <td>--pro-secondary</td>
                <td><span className={`${Style.colorIcon} pro-secondary-light`} ></span>$pro-secondary-light</td>
                <td><span className={`${Style.colorIcon} pro-secondary-dark`} ></span>$pro-secondary-dark</td>
                <td>Secondary color</td>
              </tr>
              <tr>
                <td>--pro-success</td>
                <td><span className={`${Style.colorIcon} pro-success-light`} ></span>$pro-success-light</td>
                <td><span className={`${Style.colorIcon} pro-success-dark`} ></span>$pro-success-dark</td>
                <td>Success color</td>
              </tr>
              <tr>
                <td>--pro-danger</td>
                <td><span className={`${Style.colorIcon} pro-danger-light`} ></span>$pro-danger-light</td>
                <td><span className={`${Style.colorIcon} pro-danger-dark`} ></span>$pro-danger-dark</td>
                <td>Danger/Error color</td>
              </tr>
              <tr>
                <td>--pro-light</td>
                <td><span className={`${Style.colorIcon} pro-light-light`} ></span>$pro-light-light</td>
                <td><span className={`${Style.colorIcon} pro-light-dark`} ></span>$pro-light-dark</td>
                <td>Light color</td>
              </tr>
              <tr>
                <td>--pro-dark</td>
                <td><span className={`${Style.colorIcon} pro-dark-light`} ></span>$pro-dark-light</td>
                <td><span className={`${Style.colorIcon} pro-dark-dark`} ></span>$pro-dark-dark</td>
                <td>Dark color</td>
              </tr>
              <tr>
                <td>--pro-primary-hover</td>
                <td><span className={`${Style.colorIcon} pro-primary-hover-light`} ></span>$pro-primary-hover-light</td>
                <td><span className={`${Style.colorIcon} pro-primary-hover-dark`} ></span>$pro-primary-hover-dark</td>
                <td>Primary button hover color</td>
              </tr>
              <tr>
                <td>--pro-primary-active</td>
                <td><span className={`${Style.colorIcon} pro-primary-active-light`} ></span>$pro-primary-active-light</td>
                <td><span className={`${Style.colorIcon} pro-primary-active-dark`} ></span>$pro-primary-active-dark</td>
                <td>Primary button active color</td>
              </tr>
              <tr>
                <td>--pro-secondary-hover</td>
                <td><span className={`${Style.colorIcon} pro-secondary-hover-light`} ></span>$pro-secondary-hover-light</td>
                <td><span className={`${Style.colorIcon} pro-secondary-hover-dark`} ></span>$pro-secondary-hover-dark</td>
                <td>Secondary button hover color</td>
              </tr>
              <tr>
                <td>--pro-secondary-active</td>
                <td><span className={`${Style.colorIcon} pro-secondary-active-light`} ></span>$pro-secondary-active-light</td>
                <td><span className={`${Style.colorIcon} pro-secondary-active-dark`} ></span>$pro-secondary-active-dark</td>
                <td>Secondary button active color</td>
              </tr>
              <tr>
                <td>--pro-outline</td>
                <td><span className={`${Style.colorIcon} pro-light-light`} ></span>$pro-light-light</td>
                <td><span className={`${Style.colorIcon} pro-light-dark`} ></span>$pro-light-dark</td>
                <td>Outlined button background color</td>
              </tr>
              <tr>
                <td>--pro-outline-hover</td>
                <td><span className={`${Style.colorIcon} pro-outline-hover-light`} ></span>$pro-outline-hover-light</td>
                <td><span className={`${Style.colorIcon} pro-outline-hover-dark`} ></span>$pro-outline-hover-dark</td>
                <td>Outlined button hover background color</td>
              </tr>
              <tr>
                <td>--pro-outline-active</td>
                <td><span className={`${Style.colorIcon} pro-outline-active-light`} ></span>$pro-outline-active-light</td>
                <td><span className={`${Style.colorIcon} pro-outline-active-dark`} ></span>$pro-outline-active-dark</td>
                <td>Outlined button active background color</td>
              </tr>
              <tr>
                <td>--pro-outline-border</td>
                <td><span className={`${Style.colorIcon} pro-outline-border-light`} ></span>$pro-outline-border-light</td>
                <td><span className={`${Style.colorIcon} pro-outline-border-dark`} ></span>$pro-outline-border-dark</td>
                <td>Outlined button border color</td>
              </tr>
              <tr>
                <td>--pro-disable-text</td>
                <td><span className={`${Style.colorIcon} pro-disable-text-light`} ></span>$pro-disable-text-light</td>
                <td><span className={`${Style.colorIcon} pro-disable-text-dark`} ></span>$pro-disable-text-dark</td>
                <td>Disabled element text color</td>
              </tr>
              <tr>
                <td>--pro-disable-bg</td>
                <td><span className={`${Style.colorIcon} pro-disable-bg-light`} ></span>$pro-disable-bg-light</td>
                <td><span className={`${Style.colorIcon} pro-disable-bg-dark`} ></span>$pro-disable-bg-dark</td>
                <td>Disabled element background color</td>
              </tr>
              <tr>
                <td>--pro-input-text</td>
                <td><span className={`${Style.colorIcon} pro-input-text-light`} ></span>$pro-input-text-light</td>
                <td><span className={`${Style.colorIcon} pro-input-text-dark`} ></span>$pro-input-text-dark</td>
                <td>Input text color</td>
              </tr>
              <tr>
                <td>--pro-input-hover-bg</td>
                <td><span className={`${Style.colorIcon} pro-input-hover-bg-light`} ></span>$pro-input-hover-bg-light</td>
                <td><span className={`${Style.colorIcon} pro-input-hover-bg-dark`} ></span>$pro-input-hover-bg-dark</td>
                <td>Input hover background color</td>
              </tr>
              <tr>
                <td>--pro-input-focus-shadow</td>
                <td><span className={`${Style.colorIcon} pro-input-focus-shadow-light`} ></span>$pro-input-focus-shadow-light</td>
                <td><span className={`${Style.colorIcon} pro-input-focus-shadow-dark`} ></span>$pro-input-focus-shadow-dark</td>
                <td>Input outline on focus</td>
              </tr>
              <tr>
                <td>--pro-input-active</td>
                <td><span className={`${Style.colorIcon} pro-input-active-light`} ></span>$pro-input-active-light</td>
                <td><span className={`${Style.colorIcon} pro-input-active-dark`} ></span>$pro-input-active-dark</td>
                <td>Input active background color</td>
              </tr>
              <tr>
                <td>--pro-input-disable-border</td>
                <td><span className={`${Style.colorIcon} pro-outline-border-light`} ></span>$pro-outline-border-light</td>
                <td><span className={`${Style.colorIcon} pro-outline-border-dark`} ></span>$pro-outline-border-dark</td>
                <td>Input disabled border-color</td>
              </tr>
              <tr>
                <td>--pro-avatar-remaining-bg</td>
                <td><span className={`${Style.colorIcon} pro-avatar-remaining-bg-light`} ></span>$pro-avatar-remaining-bg-light</td>
                <td><span className={`${Style.colorIcon} pro-avatar-remaining-bg-dark`} ></span>$pro-avatar-remaining-bg-dark</td>
                <td>Avatar group remaining background color</td>
              </tr>
              <tr>
                <td>--pro-nav-btn-link-hover</td>
                <td><span className={`${Style.colorIcon} pro-nav-btn-link-hover-light`} ></span>$pro-nav-btn-link-hover-light</td>
                <td><span className={`${Style.colorIcon} pro-nav-btn-link-hover-dark`} ></span>$pro-nav-btn-link-hover-dark</td>
                <td>Navigation link hover background color</td>
              </tr>
              <tr>
                <td>--pro-body-bg</td>
                <td><span className={`${Style.colorIcon} pro-light-light`} ></span>$pro-light-light</td>
                <td><span className={`${Style.colorIcon} pro-light-dark`} ></span>$pro-light-dark</td>
                <td>Body background color</td>
              </tr>
              <tr>
                <td>--pro-body-color</td>
                <td><span className={`${Style.colorIcon} pro-dark-light`} ></span>$pro-dark-light</td>
                <td><span className={`${Style.colorIcon} pro-dark-dark`} ></span>$pro-dark-dark</td>
                <td>Body text color</td>
              </tr>
            </tbody>
          </table>
      </div>

      <div className="code-area">
        <Highlight>{code}</Highlight>
      </div>
    </div>
  );
};

export default React.memo(ThemingTab);
