import React from "react";
import Style from "./NavbarTab.module.scss";
import Highlight from "react-highlight";
// import {NavGroup} from '@wac-ui-dashboard/wac_component_library'
import NavGroup from "../../Ui-widgets/NavGroup";

const code = `<h1 className="pro-ttl h1">{Text content}</h1>
<h2 className="pro-ttl h2">{Text content}</h2>
<h3 className="pro-ttl h3">{Text content}</h3>
<h4 className="pro-ttl h4">{Text content}</h4>
<h5 className="pro-ttl h5">{Text content}</h5>
<h6 className="pro-ttl h6">{Text content}</h6>
<p className="pro-font-bolder">{Text content}</p>
<p className="pro-font-bold">{Text content}</p>
<p className="pro-font-medium">{Text content}</p>
<p className="pro-font-regular">{Text content}</p>
<p className="pro-font-light">{Text content}</p>
<p className="pro-font-sm">{Text content}</p>
<p className="pro-font-xs">{Text content}</p>
`;

const NavbarTab = () => {
  const navigations = [
    { label: "Overview", link: "/", isButton: false },
    { label: "Leads", link: "/s", isButton: false },
    { label: "Tasks", link: "/tasks", isButton: false },
    { label: "Report", link: "/report", isButton: false },
    { label: "Create", icon: "+", isButton: true },
  ];
  return (
    <div className={Style.buttonWrapper}>
      <div className={"text bg-primary"}>
        <h3 className={`pro-ttl h3 pro-mb-3`}>Navbar</h3>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi,
          asperiores tenetur animi alias fuga provident deleniti praesentium
          tempore debitis accusamus impedit? Quisquam quas vel aliquam,
          molestias id reprehenderit ipsum neque! Lorem, ipsum dolor sit amet
          consectetur adipisicing elit. Excepturi, asperiores tenetur animi
          alias fuga provident deleniti praesentium tempore debitis accusamus
          impedit? Quisquam quas vel aliquam, molestias id reprehenderit ipsum
          neque!
        </p>
      </div>

      <div className={"component"}>
        <div className={"items"}>
          <NavGroup navigations={navigations} />
        </div>
      </div>

      <div className="code-area">
        <Highlight>{code}</Highlight>
      </div>
    </div>
  );
};

export default React.memo(NavbarTab);
