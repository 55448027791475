import React from 'react'
import ConfirmationBox from '../../Ui-widgets/ConfirmationBox'
import Style from './confirmationBoxTab.module.scss'
function ConfirmationBoxTab() {
  return (
    <div 
      className={Style.root}>
        <ConfirmationBox 
          title={`Delete Member`}
          subTitle={`Permanently delete this member? You can't undo this.`}
          isRight={true}
          cancelText={`Cancel`}
          submitText={`Submit`}/>
        </div>
  )
}

export default ConfirmationBoxTab