import React from 'react'
import CreatePackage from '../../Ui-widgets/CreatePackage';
import Style from './packageTab.module.scss';
function PackageTab() {
  return (
    <div className={Style.root}>
        <div className="text">
            <h3 className={`pro-ttl h3 pro-mb-3`}>Package</h3>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi, asperiores tenetur animi alias fuga provident deleniti praesentium tempore debitis accusamus impedit? Quisquam quas vel aliquam, molestias id reprehenderit ipsum neque! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi, asperiores tenetur animi alias fuga provident deleniti praesentium tempore debitis accusamus impedit? Quisquam quas vel aliquam, molestias id reprehenderit ipsum neque!</p></div>
        <div className='col-12'>
        <CreatePackage title={`Create package`}/>
    </div>
    </div>

  
  )
}

export default PackageTab