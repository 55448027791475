import React from 'react'
import Style from './activityMonitor.module.scss'
function ActivityMonitor({icon, title, children, className}) {

  return (
    <div className={`${Style.root} ${className}`}>
      {icon && <div className={`${Style.icon} pro-d-flex pro-justify-center pro-items-center`}>
       {icon}
      </div>}
      <div className={`${Style.text} pro-ps-5 pro-pt-2`}>
         {title && <p className={`${Style.title} pro-fw-medium pro-mb-0`}>{title}</p>}
         {children && <div className={`${Style.body} pro-pt-2`}>
          {children}
         </div>}
      </div>
    </div>
  )
}

export default ActivityMonitor