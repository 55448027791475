import React from 'react'
import Style from './actionBtns.module.scss'
function ActionBtns({cancelText, cancelAction, submitText, SubmitAction, isRight}) {

  return (
    <div className={Style.root}>
        <div className={`col-12 pro-d-flex ${isRight ? `pro-justify-end` : ''}`}>
            <button className="pro-btn-link lg pro-px-5" onClick={() => cancelAction()}>
                {cancelText && <span>{cancelText}</span>}
            </button>
            <button className="pro-btn pro-btn-primary lg min" onClick={() => SubmitAction()}>
                {submitText && <span>{submitText}</span>}
                </button>
            </div>
    </div>
  )
}

export default ActionBtns