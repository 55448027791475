import React from 'react';

const LeadsPage1 = () => {
    return (
        <div>
            <h2 className="h1 pro-fw-bolder">Coming soon...</h2>
        </div>
    );
}

export default LeadsPage1;
