import React from "react";
import Style from "./FromTab.module.scss";
import Highlight from "react-highlight";
import { CgSearch } from "react-icons/cg";
import Select from "react-select";
import {SelectWithIcons, IconText} from '@wac-ui-dashboard/wac_component_library';
import { FaReact } from "react-icons/fa";
import { GrWordpress } from "react-icons/gr";
import { MdBugReport, MdBrush, MdFindInPage , MdOutlineCloudUpload } from "react-icons/md";

const code = `

<div className="input-wrap">
  <input
    type="text"
    placeholder="placeholder"
    className="pro-input"
  />
  </div>


<div className="input-wrap">
  <input
    type="text"
    placeholder="placeholder"
    className="pro-input error"
  />
  <span className="error-text">This field is required.</span>
</div>


<div className="input-wrap">
  <input
    type="text"
    placeholder="placeholder"
    className="pro-input"
    disabled
  />
</div>


<div className="input-group mb-3">
  <input
    type="text"
    placeholder="Username"
    className="form-control form-control-lg icon-l"
  />
  <span className="input-group-text">
    <CgSearch size={16} />
  </span>
</div>

`;

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const optionsWithIcons = [
  { value: "react", label: "React", icon: <FaReact /> },
  { value: "wordpress", label: "Wordpress", icon: <GrWordpress /> },
  { value: "testing", label: "Testing", icon: <MdBugReport /> },
  { value: "design", label: "Design", icon: <MdBrush /> },
  { value: "seo", label: "SEO", icon: <MdFindInPage /> },
];

function FromTab() {
  return (
    <div className={Style.buttonWrapper}>
      <div className={"text"}>
        <h3 className={`pro-ttl h3 pro-mb-3`}>Forms</h3>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi,
          asperiores tenetur animi alias fuga provident deleniti praesentium
          tempore debitis accusamus impedit? Quisquam quas vel aliquam,
          molestias id reprehenderit ipsum neque! Lorem, ipsum dolor sit amet
          consectetur adipisicing elit. Excepturi, asperiores tenetur animi
          alias fuga provident deleniti praesentium tempore debitis accusamus
          impedit? Quisquam quas vel aliquam, molestias id reprehenderit ipsum
          neque!
        </p>
      </div>

      <div className={"component input"}>
        <div className={"items"}>
          <div className="input-wrap">
            <input
              type="text"
              placeholder="placeholder"
              className="pro-input"
            />
          </div>
        </div>

        <div className={"items"}>
          <div className="input-wrap">
            <input
              type="text"
              placeholder="placeholder"
              className="pro-input error"
            />
            <span className="error-text">This field is required.</span>
          </div>
        </div>

        <div className={"items"}>
          <div className="input-wrap">
            <input
              type="text"
              placeholder="placeholder"
              className="pro-input"
              disabled
            />
          </div>
        </div>

        <div className={"items"}>
          <div className="input-wrap">
            <input
              type="text"
              placeholder="placeholder"
              className="pro-input error"
              disabled
            />
            <span className="error-text">This field is required.</span>
          </div>
        </div>

        <div className={"items"}>
          <div className="input-wrap">
            <input
              type="text"
              placeholder="placeholder"
              className="pro-input icon-l"
            />
            <span className="pro-icon">
              <CgSearch />
            </span>
          </div>
        </div>

        <div className={"items"}>
          <div className="input-wrap">
            <input
              type="text"
              placeholder="placeholder"
              className="pro-input icon-l error"
            />
            <span className="error-text">This field is required.</span>
            <span className="pro-icon">
              <CgSearch />
            </span>
          </div>
        </div>

        <div className={"items"}>
          <div className="input-wrap">
            <input
              type="text"
              placeholder="placeholder"
              className="pro-input icon-l"
              disabled
            />
            <span className="pro-icon">
              <CgSearch />
            </span>
          </div>
        </div>

        <div className={"items"}>
          <div className="input-wrap">
            <input
              type="text"
              placeholder="placeholder"
              className="pro-input icon-l error"
              disabled
            />
            <span className="error-text">This field is required.</span>
            <span className="pro-icon">
              <CgSearch />
            </span>
          </div>
        </div>

        <div className={"items"}>
          <div className="input-wrap">
            <input
              type="text"
              placeholder="placeholder"
              className="pro-input icon-r"
            />
            <span className="pro-icon">
              <CgSearch />
            </span>
          </div>
        </div>

        <div className={"items"}>
          <div className="input-wrap">
            <input
              type="text"
              placeholder="placeholder"
              className="pro-input icon-r error"
            />
            <span className="error-text">This field is required.</span>
            <span className="pro-icon">
              <CgSearch />
            </span>
          </div>
        </div>

        <div className={"items"}>
          <div className="input-wrap">
            <input
              type="text"
              placeholder="placeholder"
              className="pro-input icon-r"
              disabled
            />
            <span className="pro-icon">
              <CgSearch />
            </span>
          </div>
        </div>

        <div className={"items"}>
          <div className="input-wrap">
            <input
              type="text"
              placeholder="placeholder"
              className="pro-input icon-r error"
              disabled
            />
            <span className="error-text">This field is required.</span>
            <span className="pro-icon">
              <CgSearch />
            </span>
          </div>
        </div>

        <div className={"items"}>
          <div className={"input-wrap"}>
            <Select
              options={options}
              className={"pro-input "}
              classNamePrefix="pro-input"
            />
          </div>
        </div>

        <div className={"items"} style={{ width: "100%" }}>
          <div className={"input-wrap "}>
            <Select
              isMulti
              options={options}
              className={"pro-input lg multi-select"}
              classNamePrefix="pro-input"
            />
          </div>
        </div>

        <div className={"items"} style={{ width: "100%" }}>
          <div className={"input-wrap"}>
            <SelectWithIcons options={optionsWithIcons}></SelectWithIcons>
          </div>
        </div>

        <div className={"items"} style={{ width: "100%" }}>
          <div className="input-wrap">
            <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
              Photo
            </label>
            <div className="input-drag">
              <input
                type="file"
                placeholder="placeholder"
                className="pro-input"
              />
              <span className="input-drag-box">
                <IconText icon={<MdOutlineCloudUpload size={16}/>} title={`Drop files to attach or browse`}/>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="code-area">
        <Highlight>{code}</Highlight>
      </div>
    </div>
  );
}

export default React.memo(FromTab);
