import React from "react";
import Style from "./CheckBox.module.scss";
import Highlight from "react-highlight";

const code = `
<div className="pro-check-box">
  <input type="checkbox" className="pro-check" id="checkbox" name="pro-checkbox" />
  <label htmlFor="checkbox" className="pro-check-label"> Checkbox </label>
</div>
<div className="pro-check-box">
  <input type="radio" className="pro-check" id="radio" name="pro-radio" />
  <label htmlFor="radio" className="pro-check-label"> Radio </label>
</div>
`;

function CheckBox() {
  return (
    <div className={Style.buttonWrapper}>
      <div className={"text"}>
        <h3  className={`pro-ttl h3 pro-mb-3`}>Check Box</h3>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi,
          asperiores tenetur animi alias fuga provident deleniti praesentium
          tempore debitis accusamus impedit? Quisquam quas vel aliquam,
          molestias id reprehenderit ipsum neque! Lorem, ipsum dolor sit amet
          consectetur adipisicing elit. Excepturi, asperiores tenetur animi
          alias fuga provident deleniti praesentium tempore debitis accusamus
          impedit? Quisquam quas vel aliquam, molestias id reprehenderit ipsum
          neque!
        </p>
      </div>

      <div className={"component input"}>
        <div className="items">
          <div className={"pro-check-box"}>
            <input
              type="checkbox"
              className={"pro-check"}
              id={"checkbox1"}
              name="pro-checkbox"
            />
            <label htmlFor="checkbox1" className="pro-check-label">
              {" "}
              Checkbox 1{" "}
            </label>
          </div>
        </div>
        <div className="items">
          <div className={"pro-check-box"}>
            <input
              type="checkbox"
              className={"pro-check"}
              id={"checkbox2"}
              name="pro-checkbox"
              defaultChecked
            />
            <label htmlFor="checkbox2" className="pro-check-label">
              {" "}
              Checkbox 2{" "}
            </label>
          </div>
        </div>
        <div className="items"></div>
        <div className="items"></div>
        <div className="items">
          <div className={"pro-check-box radio"}>
            <input
              type="radio"
              className={"pro-check"}
              id={"radio1"}
              name="pro-radio"
              
            />
            <label htmlFor="radio1" className="pro-check-label">
              {" "}
              Radio 1{" "}
            </label>
          </div>
        </div>
        <div className="items">
          <div className={"pro-check-box radio"}>
            <input
              type="radio"
              className={"pro-check"}
              id={"radio2"}
              name="pro-radio"
              defaultChecked
            />
            <label htmlFor="radio2" className="pro-check-label">
              {" "}
              Radio 2{" "}
            </label>
          </div>
        </div>
      </div>

      <div className="code-area">
        <Highlight>{code}</Highlight>
      </div>
    </div>
  );
}

export default React.memo(CheckBox);
