import React from "react";
import Layout from "../../Ui-components/Layout";

const TasksPage1 = () => {
  return (
    <Layout>
      <h2 className="h1 pro-fw-bolder">Coming Soon...</h2>
    </Layout>
  );
};

export default TasksPage1;
