import React, { useState } from "react";
import { Nav, Row, Tab } from "react-bootstrap";
import Welcome from "../../Tabs/Welcome";
import Button from "../../Tabs/ButtonTab";
import Style from "./Home.module.scss";
import ButtonGroupTab from "../../Tabs/ButtonGroupTab";
import FromTab from "../../Tabs/FromTab";
import AvatarTab from "../../Tabs/AvatarTab";
import CheckBox from "../../Tabs/CheckBox";
import PaginationTab from "../../Tabs/PaginationTab";
import BadgesTab from "../../Tabs/BadgesTab";
import BootstrapFormTab from "../../Tabs/BootstrapFormTab";
import SpinnerTab from "../../Tabs/SpinnerTab";
import DepartmentIconsTab from "../../Tabs/DepartmentIconsTab";
import TypographyTab from "../../Tabs/TypographyTab";
import TableTab from "../../Tabs/TableTab";
import NavbarTab from "../../Tabs/NavbarTab";
import ThemingTab from "../../Tabs/ThemingTab";
import CardTab from "../../Tabs/CardTab";
import ProfileCardTab from "../../Tabs/ProfileCardTab";
import MemberRegistrationTab from "../../Tabs/MemberRegistrationTab";
import DatePickerTab from "../../Tabs/DatePickerTab";
import AuthTab from "../../Tabs/AuthTab";
import RuleTab from "../../Tabs/ruleTab";
import PackageTab from "../../Tabs/PackageTab";
import PaymentWidgetTab from "../../Tabs/PaymentWidgetTab";
import WarningBoxTab from "../../Tabs/warningBoxTab";
import SimpleReportTextTab from "../../Tabs/SimpleReportTextTab";
import ActionBtnsTab from "../../Tabs/ActionBtnsTab";
import ShareTab from "../../Tabs/ShareTab";
import PreferenceTab from "../../Tabs/PreferenceTab";
import BulkEditTab from "../../Tabs/BulkEditTab";
import OrderColumnTab from "../../Tabs/OrderColumnTab";
import ConfirmationBoxTab from "../../Tabs/ConfirmationBoxTab";
import ActivityMonitorTab from "../../Tabs/ActivityMonitorTab";
import TableShimmerTab from "../../Tabs/TableShimmerTab";

function Home() {
  const [darkMode, setDarkMode] = useState(false);
  const [next, setNext] = useState('getStarted');


  const toggleTheme = ()=>{
    setDarkMode(!darkMode);
    document.documentElement.setAttribute(
      "data-theme",
      darkMode ? "light" : "dark"
    );
  };

  return (
    <div className={`${Style.homeWrapper} home ${darkMode ? "dark" : ""}`}>
      <button className={Style.darkModeBtn} onClick={toggleTheme}>
        {!darkMode ? `Switch to DarkMode` : `witch to light Mode`}
      </button>

      <div className={`pro-container-inner pro-ps-0`}>
        <div className={Style.tabWrapper}>

          <Tab.Container id="left-tabs-example" defaultActiveKey="getStarted" activeKey={`${next}`}>
            <Row>
              <div className={Style.navWrapper}>
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="getStarted" onClick={()=>setNext(`getStarted`)}>Get Started</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="theming" onClick={()=>setNext(`theming`)}>Theme customisation</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="buttons" onClick={()=>setNext(`buttons`)}>Buttons</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="buttonGroup" onClick={()=>setNext(`buttonGroup`)}>Button Group</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="bootsrapForms" onClick={()=>setNext(`bootsrapForms`)}>Bootsrap Forms</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="forms" onClick={()=>setNext(`forms`)}>Forms</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="avatar" onClick={()=>setNext(`avatar`)}>Avatar</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="checkBox" onClick={()=>setNext(`checkBox`)}>CheckBox</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="pagination" onClick={()=>setNext(`pagination`)}>Pagination</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="badges" onClick={()=>setNext(`badges`)}>Badges</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="spinner" onClick={()=>setNext(`spinner`)}>Spinner</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="departmentIcons" onClick={()=>setNext(`departmentIcons`)}>Department Icons</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="typography" onClick={()=>setNext(`typography`)}>Typography</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="table" onClick={()=>setNext(`table`)}>Table</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="navbar" onClick={()=>setNext(`navbar`)}>Navbar</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="datepicker" onClick={()=>setNext(`datepicker`)}>Date picker</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="cards" onClick={()=>setNext(`cards`)}>Widgets</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="profileCard" onClick={()=>setNext(`profileCard`)}>Profile Card</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="memberRegistration" onClick={()=>setNext(`memberRegistration`)}>Member Registration</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="auth" onClick={()=>setNext(`auth`)}>Authentication</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="rule" onClick={()=>setNext(`rule`)}>Rule Widgets</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="package" onClick={()=>setNext(`package`)}>Package</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="payment" onClick={()=>setNext(`payment`)}>Payment</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="warning" onClick={()=>setNext(`warning`)}>Warning</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="simpleReport" onClick={()=>setNext(`simpleReport`)}>SimpleReport</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="actionBtns" onClick={()=>setNext(`actionBtns`)}>ActionBtns</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Share" onClick={()=>setNext(`Share`)}>Share</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="preference" onClick={()=>setNext(`preference`)}>Preference</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="bulkEdit" onClick={()=>setNext(`bulkEdit`)}>Bulk Edit</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="orderColumn" onClick={()=>setNext(`orderColumn`)}>Order Column</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="ConfirmationBoxTab" onClick={()=>setNext(`ConfirmationBoxTab`)}>Confirmation Box</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="ActivityMonitor" onClick={()=>setNext(`ActivityMonitor`)}>Activity Monitor</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="shimmerTab" onClick={()=>setNext(`shimmerTab`)}>Shimmers</Nav.Link>
                  </Nav.Item>

                  
                  

                  

                  
                </Nav>
              </div>

              <div className={Style.mainWrapper}>
                <Tab.Content>
                  <Tab.Pane eventKey="getStarted">
                    <Welcome setNext={setNext} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="theming">
                    <ThemingTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="buttons">
                    <Button />
                  </Tab.Pane>
                  <Tab.Pane eventKey="buttonGroup">
                    <ButtonGroupTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="bootsrapForms">
                    <BootstrapFormTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="forms">
                    <FromTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="avatar">
                    <AvatarTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="checkBox">
                    <CheckBox /> 
                  </Tab.Pane>
                  <Tab.Pane eventKey="pagination">
                    <PaginationTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="badges">
                    <BadgesTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="spinner">
                    <SpinnerTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="departmentIcons">
                    <DepartmentIconsTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="typography">
                    <TypographyTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="table">
                    <TableTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="navbar">
                    <NavbarTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="datepicker">
                    <DatePickerTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="cards">
                    <CardTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="profileCard">
                    <ProfileCardTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="memberRegistration">
                    <MemberRegistrationTab />
                  </Tab.Pane>
                  <Tab.Pane eventKey="auth">
                    <AuthTab/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="rule">
                    <RuleTab/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="package">
                    <PackageTab/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="payment">
                    <PaymentWidgetTab/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="warning">
                    < WarningBoxTab/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="simpleReport">
                    <SimpleReportTextTab/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="actionBtns">
                    <ActionBtnsTab/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Share">
                    <ShareTab/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="preference">
                    <PreferenceTab/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="bulkEdit">
                    <BulkEditTab/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="orderColumn">
                    <OrderColumnTab/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="ConfirmationBoxTab">
                    <ConfirmationBoxTab/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="ActivityMonitor">
                    <ActivityMonitorTab/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="shimmerTab">
                    <TableShimmerTab/>
                  </Tab.Pane>

                  


                 

                  

                  
                </Tab.Content>
              </div>
            </Row>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
}

export default Home;
