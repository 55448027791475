import React from "react";
import DataContainer from "../../Ui-widgets/DataContainer";
import SimpleReportText from "../../Ui-widgets/SimpleReportText";
import Style from "./SimpleReportTextTab.module.scss";

const SimpleReportTextTab = () => {
  return (
    <div className={Style.root}>
        <DataContainer>
            <SimpleReportText/>
        </DataContainer>
    </div>
  );
};

export default React.memo(SimpleReportTextTab);
