import React from "react";
import Layout from "../../Ui-components/Layout";
import MenuDrawer from "../../Ui-components/MenuDrawer";
import { NavLink, Outlet } from "react-router-dom";
import { Nav } from "react-bootstrap";

const LeadsPage = () => {
  const drawerMenu = [
    { title: "Leads 1", link: "/leads", iconAsset: "DrawerIcon1" },
    { title: "Leads 2", link: "/leads/leads2", iconAsset: "DrawerIcon2" },
    { title: "Leads 3", link: "/leads/leads3", iconAsset: "DrawerIcon3" },
  ];
  return (
    <Layout>
      <div className="pro-d-flex">
        <MenuDrawer
        menus={drawerMenu}
        Nav={Nav}
        NavLink={NavLink}
        icon={<span className="material-symbols-outlined"> chevron_right </span>}
        type={`type-2`}
        goBackIcon={<span className="material-symbols-outlined"> chevron_left </span>}
        goBackTittle={`Back to role`}
        isGoBack
        ></MenuDrawer>
        <div className="pro-page-wrp">
          <div className="pro-container-inner">
            <Outlet></Outlet>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LeadsPage;
