import React from 'react'
import { MdAddCircleOutline, MdDelete } from 'react-icons/md'
import Style from './packageRule.module.scss'
import Select from "react-select";
const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
function PackageRule({ title }) {
  return (
    <div className={Style.root}>
      <div className="col-12">
        {title && <h5 className="pro-ttl h5 pro-mb-3">{title}</h5>}
        <div className={`${Style.root_rule} pro-p-2 pro-mb-3`}>
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col-lg-4 pro-pb-1 pro-pb-lg-0">
                  <Select
                    options={options}
                    className={"pro-input "}
                    classNamePrefix="pro-input"
                    defaultValue={options[0]}
                  />
                </div>
                <div className="col-lg-4 pro-pb-1 pro-pb-lg-0">
                  <Select
                    options={options}
                    className={"pro-input "}
                    classNamePrefix="pro-input"
                    defaultValue={options[0]}
                  />
                </div>
                <div className="col-lg-4 pro-pb-1 pro-pb-lg-0">
                  <Select
                    options={options}
                    className={"pro-input "}
                    classNamePrefix="pro-input"
                    defaultValue={options[0]}
                  />
                </div>
              </div>
            </div>
            <div className={`${Style.deleteAction}`}>
              <button className='pro-btn-link'>
                <MdDelete />
              </button>
            </div>
          </div>
        </div>
        <div className={`${Style.root_rule} pro-p-2 pro-mb-3`}>
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col-lg-4 pro-pb-1 pro-pb-lg-0">
                  <Select
                    options={options}
                    className={"pro-input "}
                    classNamePrefix="pro-input"
                    defaultValue={options[0]}
                  />
                </div>
                <div className="col-lg-4 pro-pb-1 pro-pb-lg-0">
                  <Select
                    options={options}
                    className={"pro-input "}
                    classNamePrefix="pro-input"
                    defaultValue={options[0]}
                  />
                </div>
                <div className="col-lg-4 pro-pb-1 pro-pb-lg-0">
                  <Select
                    options={options}
                    className={"pro-input "}
                    classNamePrefix="pro-input"
                    defaultValue={options[0]}
                  />
                </div>
              </div>
            </div>
            <div className={`${Style.deleteAction}`}>
              <button className='pro-btn-link'>
                <MdDelete />
              </button>
            </div>
          </div>
        </div>
        <div className="col-12">
          <button className="pro-btn pro-btn-outline lg">
            <MdAddCircleOutline />
            <span>Add Rule</span>
          </button>
        </div>

      </div>
    </div>
  )
}

export default PackageRule