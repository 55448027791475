import React from 'react'
import ActionBtns from '../ActionBtns'
import Style from './confirmationBox.module.scss'
function ConfirmationBox({
  /**
   * Please call {submitAction, cancelAction} function inside the this component.
   */
  title,
  subTitle,
  submitText, 
  submitAction,
  cancelText,
  cancelAction,
  isRight
}) {
  return (
    <div className={`${Style.root} pro-p-5`}>
      {(title || subTitle) && <div className={`${Style.rootText} pro-pb-4`}>
        <h6 className={`pro-ttl h6 pro-mb-1`}>{title}</h6>
        <p className={`pro-fw-medium pro-mb-0`}>{subTitle}</p>
      </div>}
      {(submitText || cancelText) && 
        <div className={`pro-pt-4`}>
          <ActionBtns submitText={submitText} cancelText={cancelText} isRight={isRight}/>
        </div>}
    </div>
  )
}

export default ConfirmationBox