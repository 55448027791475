import React from "react";
import Layout from "../../Ui-components/Layout";

const ReportsPage = () => {
  return (
    <Layout>
      <div className="pro-page-wrp">
        <div className="pro-container-inner">
          <h2 className="h1 pro-fw-bolder">Coming Soon...</h2>
        </div>
      </div>
    </Layout>
  );
};

export default ReportsPage;
