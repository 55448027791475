import React from 'react'
import { Header } from '@wac-ui-dashboard/wac_component_library'
// import Style from './Layout.module.scss'
import Assets from '../../Assets/Assets'
import { Link, NavLink } from 'react-router-dom';
const navigations = [
  { label: "Overview", link: "/overview", isButton: false },
  { label: "Leads", link: "/leads", isButton: false },
  { label: "Tasks", link: "/tasks", isButton: false },
  { label: "Report", link: "/report", isButton: false },
  { label: "Create", isButton: true },
];
function Layout(props) {
    const { children } = props;
  return (
   <>
    <Header logo={Assets.LOGO} navigations={navigations} NavLink={NavLink} Link={Link}/>
    <main>
        {children}
    </main>
    <footer>
        
    </footer>
   </>
  )
}

export default Layout