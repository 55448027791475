import React from "react";
import Style from "./DepartmentIconsTab.module.scss";
import Highlight from "react-highlight";
import { FaReact } from "react-icons/fa";
import { GrWordpress } from "react-icons/gr";
import { MdBugReport, MdBrush, MdFindInPage } from "react-icons/md";

const code = `import { FaReact } from "react-icons/fa";
import {GrWordpress} from 'react-icons/gr';
import {MdBugReport,MdBrush,MdFindInPage} from "react-icons/md";

<div className='pro-avatar department-react'>
  <FaReact />
</div>
<div className='pro-avatar department-react lg'>
  <FaReact />
</div>
<div className='pro-avatar department-wordpress'>
  <GrWordpress />
</div>
<div className='pro-avatar department-wordpress lg'>
  <GrWordpress />
</div>
<div className='pro-avatar department-testing'>
  <MdBugReport />
</div> 
<div className='pro-avatar department-testing lg'>
  <MdBugReport />
</div>
<div className='pro-avatar department-design'>
  <MdBrush />
</div>
<div className='pro-avatar department-design lg'>
  <MdBrush />
</div>
<div className='pro-avatar department-design'>
  <MdBrush />
</div>
<div className='pro-avatar department-design lg'>
  <MdBrush />
</div>
<div className='pro-avatar department-seo'>
  <MdFindInPage />
</div>
<div className='pro-avatar department-seo'>
  <MdFindInPage />
</div>`;

const DepartmentIconsTab = () => {
  return (
    <div className={Style.buttonWrapper}>
      <div className={"text bg-primary"}>
        <h3  className={`pro-ttl h3 pro-mb-3`}>Department icons</h3>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi,
          asperiores tenetur animi alias fuga provident deleniti praesentium
          tempore debitis accusamus impedit? Quisquam quas vel aliquam,
          molestias id reprehenderit ipsum neque! Lorem, ipsum dolor sit amet
          consectetur adipisicing elit. Excepturi, asperiores tenetur animi
          alias fuga provident deleniti praesentium tempore debitis accusamus
          impedit? Quisquam quas vel aliquam, molestias id reprehenderit ipsum
          neque!
        </p>
      </div>

      <div className={"component input"}>
        <div className={"items"}>Icon React</div>
        <div className={"items"}>
          <div className="pro-avatar department-react">
            <FaReact />
          </div>
        </div>
        <div className={"items"}>
          <div className="pro-avatar department-react lg">
            <FaReact />
          </div>
        </div>
      </div>
      <div className={"component input"}>
        <div className={"items"}>Icon Wordpress</div>
        <div className={"items"}>
          <div className="pro-avatar department-wordpress">
            <GrWordpress />
          </div>
        </div>
        <div className={"items"}>
          <div className="pro-avatar department-wordpress lg">
            <GrWordpress />
          </div>
        </div>
      </div>

      <div className={"component input"}>
        <div className={"items"}>Icon Testing</div>
        <div className={"items"}>
          <div className="pro-avatar department-testing">
            <MdBugReport />
          </div>
        </div>
        <div className={"items"}>
          <div className="pro-avatar department-testing lg">
            <MdBugReport />
          </div>
        </div>
      </div>
      <div className={"component input"}>
        <div className={"items"}>Icon Design</div>
        <div className={"items"}>
          <div className="pro-avatar department-design">
            <MdBrush />
          </div>
        </div>
        <div className={"items"}>
          <div className="pro-avatar department-design lg">
            <MdBrush />
          </div>
        </div>
      </div>
      <div className={"component input"}>
        <div className={"items"}>Icon Seo</div>
        <div className={"items"}>
          <div className="pro-avatar department-seo">
            <MdFindInPage />
          </div>
        </div>
        <div className={"items"}>
          <div className="pro-avatar department-seo lg">
            <MdFindInPage />
          </div>
        </div>
      </div>

      <div className="code-area">
        <Highlight>{code}</Highlight>
      </div>
    </div>
  );
};

export default React.memo(DepartmentIconsTab);
