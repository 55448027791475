import React from 'react';
import { card_widget_01, card_widget_02 } from '../../dummy_data';
import Select from "react-select";
import { IconText } from '@wac-ui-dashboard/wac_component_library'
import { MdImage, MdLaunch } from 'react-icons/md';
import { Cards, ListingItems, UpdateDivider, TextCheckBox, StatusIcon, SimpleArticle, HeadingGroup} from '@wac-ui-dashboard/wac_component_library';
const options = [
    { value: "pending", label: "Pending" },
    { value: "completed", label: "Completed" },
    { value: "onprogress", label: "On Progress" },];

function CardTab() {
    return (
        <div className='col'>
            <div className='row'>
                <div className='col'>
                    <Cards title={`Meeting`}>
                        {card_widget_01 && card_widget_01.map(elem => {
                            return (
                                <ListingItems
                                    key={elem.id}
                                    title={elem.title}
                                    time={elem.time}
                                    icon={elem.icon}
                                    linkIcon={<MdLaunch/>}
                                />)
                        })}

                    </Cards>
                </div>
                <div className='col'>
                    <Cards title={`Meeting`}>
                        {card_widget_02 && card_widget_02.map(elem => {
                            return (
                                <TextCheckBox title={elem.title} key={elem.id} id={elem.id} priority={elem.priority} />
                            )
                        })}
                    </Cards>
                </div>
                <div className='col'>
                    <Cards title={`Meeting`}>
                        <UpdateDivider />
                        {card_widget_01 && card_widget_01.map(elem => {
                            return (
                                <ListingItems
                                    key={elem.id}
                                    title={elem.title}
                                    time={elem.time}
                                    icon={elem.icon}
                                    noLink
                                />)
                        })}
                    </Cards>
                </div>
                <div className='col-12'>
                    <div className='row'>
                        <div className='col-6  pro-mt-5'>
                            <Cards headless>
                                <p className='pro-font-regular pro-mb-0 fade-1'>Achived</p>
                                <h5 className='pro-ttl h5 pro-mb-2'>$9,257.51</h5>
                                <p className="pro-font-xs pro-mb-0 fade-1">Target 11,633.07</p>
                            </Cards>
                        </div>
                        <div className='col-6  pro-mt-5'>
                            <SimpleArticle title={'title'} description={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur architecto ea quisquam! Exercitationem omnis eos, labore tempore earum quaerat excepturi non? Dolor ducimus vel ipsa a necessitatibus voluptas debitis animi.`}/>
                        </div>
                        <div className='col-1  pro-mt-5'>
                            <Cards headless>
                                <StatusIcon/>
                            </Cards>
                        </div>
                        <div className='col-1  pro-mt-5'>
                            <Cards headless>
                                <StatusIcon checked/>
                            </Cards>
                        </div>
                        <div className='col-10 pro-mt-5'>
                            <HeadingGroup title={`Overview`}>
                            <Select
                                options={options}
                                defaultValue={options[0]}
                                classNamePrefix="pro-input"
                                className={"pro-input pro-ms-auto lg pro-w-auto filled"}
                            />
                            </HeadingGroup>
                        </div>
                        <div className='col-6 pro-mt-5'>
                            <Cards headless>
                                <IconText icon={<MdImage size={16}/>} title={`4 images`}/>
                            </Cards>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default React.memo(CardTab);