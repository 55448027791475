import React from 'react'
import ActionBtns from '../ActionBtns'
import Style from './preference.module.scss'
function Preference({EditBtnIcon, title, data}) {
  return (
    <div className={Style.root}>
        <div className={Style.heading}>
            {title && <h6 className="pro-ttl h6 pro-mb-">{title}</h6>}
            <div className={Style.actionBtn}>
              <button className={`pro-btn-link pro-p-2`}>{EditBtnIcon}</button>
              <ActionBtns submitText={'create'} cancelText={`cancel`} isRight/>.
            </div>
            
        </div>
        <div className={Style.body}>
            <div className={Style.itemWrapper}>
              {data && data.map(item => {
                return( 
                    <span key={item.id} className={`pro-badge badge-grey-outline lg pro-px-4 pro-py-1 pro-m-1`}><p className={`pro-mb-0`}>{item.title}</p></span> 
                )
              })}      
              <span className={`pro-badge badge-grey-outline lg pro-py-1 ${ Style.readMore}`}><p className={`pro-mb-0`}>{8} more</p></span>                
            </div>
        </div>


        <div className={Style.body}>
          <div className={Style.itemWrapper}>
            <div className={`row`}>
              <div className={`col-12 col-lg-3 pro-py-4`}>
                <div className="input-wrap">
                  <label for="" className="pro-font-sm pro-mb-1 pro-fw-medium">Photo</label>
                  <input type="text" placeholder="placeholder" className="pro-input lg" />
                </div>
              </div>
              <div className={`col-12 col-lg-3 pro-py-4`}>
                <div className="input-wrap">
                  <label for="" className="pro-font-sm pro-mb-1 pro-fw-medium">Martial Status</label>
                  <input type="text" placeholder="placeholder" className="pro-input lg" />
                </div>
              </div>
              <div className={`col-12 col-lg-3 pro-py-4`}>
                <div className="input-wrap">
                  <label for="" className="pro-font-sm pro-mb-1 pro-fw-medium">Height</label>
                  <input type="text" placeholder="placeholder" className="pro-input lg" />
                </div>
              </div>

              <div className={`col-12 col-lg-3 pro-py-4`}>
                <div className="input-wrap">
                  <label for="" className="pro-font-sm pro-mb-1 pro-fw-medium">Complexion</label>
                  <input type="text" placeholder="placeholder" className="pro-input lg" />
                </div>
              </div>
              <div className={`col-12 col-lg-3 pro-py-4`}>
                <div className="input-wrap">
                  <label for="" className="pro-font-sm pro-mb-1 pro-fw-medium">Weight</label>
                  <input type="text" placeholder="placeholder" className="pro-input lg" />
                </div>
              </div>
              <div className={`col-12 col-lg-3 pro-py-4`}>
                <div className="input-wrap">
                  <label for="" className="pro-font-sm pro-mb-1 pro-fw-medium">Mother Tongue</label>
                  <input type="text" placeholder="placeholder" className="pro-input lg" />
                </div>
              </div>
              <div className={`col-12 col-lg-3 pro-py-4`}>
                <div className="input-wrap">
                  <label for="" className="pro-font-sm pro-mb-1 pro-fw-medium">Community</label>
                  <input type="text" placeholder="placeholder" className="pro-input lg" />
                </div>
              </div>
              <div className={`col-12 col-lg-3 pro-py-4`}>
                <div className="input-wrap">
                  <label for="" className="pro-font-sm pro-mb-1 pro-fw-medium">Employed In</label>
                  <input type="text" placeholder="placeholder" className="pro-input lg" />
                </div>
              </div>

              <div className={`col-12 col-lg-3 pro-py-4`}>
                <div className="input-wrap">
                  <label for="" className="pro-font-sm pro-mb-1 pro-fw-medium">Diocese</label>
                  <input type="text" placeholder="placeholder" className="pro-input lg" />
                </div>
              </div>
              <div className={`col-12 col-lg-3 pro-py-4`}>
                <div className="input-wrap">
                  <label for="" className="pro-font-sm pro-mb-1 pro-fw-medium">Physical Status</label>
                  <input type="text" placeholder="placeholder" className="pro-input lg" />
                </div>
              </div>
              <div className={`col-12 col-lg-3 pro-py-4`}>
                <div className="input-wrap">
                  <label for="" className="pro-font-sm pro-mb-1 pro-fw-medium">Body Type</label>
                  <input type="text" placeholder="placeholder" className="pro-input lg" />
                </div>
              </div>
              <div className={`col-12 col-lg-3 pro-py-4`}>
                <div className="input-wrap">
                  <label for="" className="pro-font-sm pro-mb-1 pro-fw-medium">Current Location*</label>
                  <input type="text" placeholder="placeholder" className="pro-input lg" />
                </div>
              </div>

              <div className={`col-12 col-lg-3 pro-py-4`}>
                <div className="input-wrap">
                  <label for="" className="pro-font-sm pro-mb-1 pro-fw-medium">Occupation</label>
                  <input type="text" placeholder="placeholder" className="pro-input lg" />
                </div>
              </div>
              <div className={`col-12 col-lg-3 pro-py-4`}>
                <div className="input-wrap">
                  <label for="" className="pro-font-sm pro-mb-1 pro-fw-medium">Family Status</label>
                  <input type="text" placeholder="placeholder" className="pro-input lg" />
                </div>
              </div>
              <div className={`col-12 col-lg-3 pro-py-4`}>
                <div className="input-wrap">
                  <label for="" className="pro-font-sm pro-mb-1 pro-fw-medium">Education</label>
                  <input type="text" placeholder="placeholder" className="pro-input lg" />
                </div>
              </div>
              <div className={`col-12 col-lg-3 pro-py-4`}>
                <div className="input-wrap">
                  <label for="" className="pro-font-sm pro-mb-1 pro-fw-medium">Citizenship</label>
                  <input type="text" placeholder="placeholder" className="pro-input lg" />
                </div>
              </div>

              
            </div>
          </div>
        </div>
    </div>
  )
}

export default Preference