import React from 'react'
import Style from './userCardWrapper.module.scss'
function UserCardWrapper({children}) {
  return (
    <div className={`${Style.root}`}>
        <div className={`${Style.root_inner}`}>
            {children || `Please add <UserCard/>`}
        </div>
    </div>
  )
}

export default UserCardWrapper