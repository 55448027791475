import Modal from 'react-bootstrap/Modal'
import React from 'react'
import Style from './modalLayout.module.scss'
function ModalLayout({closeIcon, title, handleClose, children}) {
  return (
    <div show={true} className={Style.root}>
    {/* <Modal show={true} className={Style.root}> */}
        <div className={`${Style.root_header} pro-p-5 pro-d-flex pro-justify-between pro-items-center`}>
            {title && <h5 className="pro-ttl h5 pro-mb-0">{title}</h5>}
            {closeIcon && <button className={`pro-btn-link pro-p-0`} onClick={() => handleClose()}>{closeIcon}</button>}
            
        </div>

        <div className={`${Style.root_body}`}>
          {children}
        </div>



    {/* </Modal> */}
    </div>
  )
}

export default ModalLayout