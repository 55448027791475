export const navigations = [
    { label: "Dashboard", link: "/dashboard", isButton: false },
    { label: "Users", link: "/users", isButton: false },
    { label: "Member", link: "/member", isButton: false },
    { label: "Packages", link: "/packages", isButton: false },
    { label: "Payments", link: "/payments", isButton: false },
    { label: "Messages", link: "/messages", isButton: false },
  ];



export const profilesData = [
    {id: 1, userName: 'Nithin Varghese ', image : "https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"},
    {id: 2, userName: 'Lara Croft', image : "https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"},
    {id: 3, userName: 'Jane Doe', image : "https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"},
]


export const profilesImageSlider = [
  {id: 1, image : "https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"},
  {id: 2, image : "https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"},
  {id: 3, image : "https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"},
]


export const tableData01 = [
  {id: 1, heading: 'Marital Status', data: 'Unmarried'},
  {id: 2, heading: 'Age & Date of Birth', data: '26, 22-6-1996'},
  {id: 3, heading: 'Weight', data: '56'},
  {id: 4, heading: 'Height', data: '164 cm'},
  {id: 5, heading: 'Body Type', data: 'Slim'},
  {id: 6, heading: 'Blood Group', data: 'Normal'},
  {id: 7, heading: 'Physical Status', data: 'Fair'},
  {id: 8, heading: 'Complexion', data: 'Fair'},
  {id: 9, heading: 'Blood Group', data: 'O+ve'},
  {id: 10, heading: 'Birth Place', data: 'Kerala'},
  {id: 11, heading: 'Profile Created By', data: 'Kerala'},
]



export const navigationsNew = [
  { label: "Overview", link: "/profile-over-view", isButton: false },
  { label: "Matches", link: "/matches", isButton: false },
  { label: "Shortlisted", link: "/tasks", isButton: false },
  { label: "Favourites", link: "/report", isButton: false },
  { label: "Profiles viewed", link: "/report", isButton: false },
  { label: "Viewed by others", link: "/report", isButton: false },
  { label: "Payment", link: "/report", isButton: false },
  { label: "Activity", link: "/report", isButton: false },
  { label: "Settings", link: "/report", isButton: false }
];



export const tableData02 = [
  {id: 1, heading: 'Education Category', data: 'Bachelors Engineering'},
  {id: 2, heading: 'Education Details', data: 'Btech ECE'},
  {id: 3, heading: 'Institution', data: 'Amal Jyothi college of engineering'},
  {id: 4, heading: 'Pass-out year', data: '2017'},
]


export const tableData03 = [
  {id: 1, heading: 'Occupation Category', data: 'Computer / IT Engineer'},
  {id: 2, heading: 'Occupation Details', data: 'Software engineer at IBM'},
  {id: 3, heading: 'Firm Name', data: 'IBM'},
  {id: 4, heading: 'Employment Sector', data: 'Private'},
  {id: 5, heading: 'Work Location', data: 'Bangalore, India'},
  {id: 6, heading: 'Annual Income', data: '10 - 15 Lakh'},
]



export const tableData04 = [
  {id: 1, heading: 'Father’s Name', data: 'Thomas Mathew'},
  {id: 2, heading: 'Father’s Occupation', data: 'Business'},
  {id: 3, heading: 'Mother’s Name', data: 'Ancy'},
  {id: 4, heading: 'Mother’s Occupation', data: 'Home Maker'},
  {id: 5, heading: 'Siblings Details', data: 'Amal Jyothi college of engineering'},
]
