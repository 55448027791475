import React from 'react';
import { MdArrowForward } from 'react-icons/md';
// import context from '../../Ui-components/context';
// import HomeCards from './homeCards';
import Style from './welcome.module.scss'




function Welcome({setNext}) {
  return (
    <div className={Style.homeWrapper}>
        <div className={'text'}>
          <h3 className={`pro-ttl h3 pro-mb-3`}>Get started with <b>WAC Pro UI Dashboard</b></h3>
          <p className='pro-fw-regular'>
            The WAC Pro user interface dashboard is a simple, upgradeable and flexible component. Built for Creating pixel perfect ui for our dashboards.
            Spend less time for the UI part and incre ase the productivity.  
          </p>
          <br/>
          <button className="pro-btn pro-btn-primary lg" onClick={() => setNext(`theming`)}>
            <span>Get Started</span> <MdArrowForward />
          </button>
        </div>


       
    </div>
  )
}

export default React.memo(Welcome)