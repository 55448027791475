import React from 'react'
import Style from './switchableNav.module.scss'

function SwitchableNav() {
    return (
        <div className={Style.root}>
            <div className={`${Style.root_inner} pro-d-flex`}>
                <div className={`${Style.root_item} ${Style.active}`}>
                    <button className={`pro-btn-link pro-text-center pro-w-100 full pro-p-4`}>
                        Add Payment
                    </button>
                </div>
                <div className={`${Style.root_item}`}>
                    <button className={`pro-btn-link pro-text-center pro-w-100 full pro-p-4`}>
                        History
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SwitchableNav