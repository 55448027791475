import React, { useState } from "react";
import { CgSearch } from "react-icons/cg";
import { Offcanvas } from "react-bootstrap";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import Select from "react-select";
import Style from "./SearchFilters.module.scss";
import DatePickerWithInput from "../DatePickerWithInput";
import { addDays } from "date-fns";

const SearchFilters = ({ filters, onSearchInput, onSearchInputBlur }) => {

  //options
  const options = [
    { value: "20", label: "20 per page" },
    { value: "40", label: "40 per page" },
    { value: "60", label: "60 per page" },
  ];

  //checkShow
  const [show, setShow] = useState(false);

  //handle Close
  const handleClose = () => setShow(false);

  //handle Show
  const handleShow = () => setShow(true);

  //date Range
  const dateRange = {
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  };

  

  //return
  return (
    <div className="row gx-2 gy-2 flex-fill">
      {filters.map((filter, index) => {

        //return false
        if (filter.isInFilters) {return false;}


        return (
          <div
            className={
              filter.type === "actions"
                ? `col-auto pro-d-flex pro-items-start pro-ms-auto ${Style.table_length_select}`
                : `col-auto`
            }
            key={index}>


              {filter.type === "search" ? (

                  <div className="input-wrap">
                    <input
                      type="text"
                      onInput={onSearchInput}
                      onBlur={onSearchInputBlur}
                      placeholder="placeholder"
                      className={`pro-input icon-r lg ${Style.search_table}`}
                    />
                    <span className="pro-icon">
                      <CgSearch />
                    </span>
                  </div>
                ) 
                
                : 
                
                
                filter.type === "buttonGroup" ? (
                  
                  <div className="pro-btn-group">
                    {filter.fields.map((button, index) => {
                      return (
                        <button
                          key={index}
                          className={`pro-btn pro-btn-outline lg ${
                            button.isActive ? `active` : ``
                          }`}
                        >
                          {button.label} ({button.count})
                        </button>
                      );
                    })}
                  </div>
                )
                
                
                : 
                
                
                filter.type === "date" ? (
                  <DatePickerWithInput range initialValue={dateRange}></DatePickerWithInput>
                ) 
                
                
                : 
                
                
                filter.type === "filters" ? (
                  <button
                    className="pro-btn pro-btn-outline lg"
                    onClick={handleShow}
                  >
                    <TbAdjustmentsHorizontal size={14} className="pro-me-1" />
                    Filters
                  </button>
                ) 
                
                
                : 
                
                
                filter.type === "actions" ? (
                  <Select
                    options={options}
                    defaultValue={options[0]}
                    classNamePrefix="pro-input"
                    className={"pro-input pro-ms-auto lg pro-w-auto filled"}
                  />
                ) 
                
                : ("")
            
              }
          </div>
        );
      })}




      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="pro-rounded-start"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title as="h5">Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>

          Items need to Append here!!
        </Offcanvas.Body>
      </Offcanvas>
      
    </div>
  );
};

export default SearchFilters;
