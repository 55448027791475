import React from 'react'
import Style from './dataContainer.module.scss'

function DataContainer({children}) {
  return (
    <div className={`${Style.root} pro-p-4`}>
        {children || `Please add data`}
    </div>
  )
}

export default DataContainer