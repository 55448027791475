import React from 'react';
import WarningBox from '../../Ui-widgets/WarningBox';
// import Style from './profileCardTab.module.scss';
function warningBoxTab() {
    return (
        <div className='col-5'>
           <WarningBox  percentage={90} button={{name: 'button'}} title={`Profile Incomplete`} type={`type-2`}/>
        </div>
    )
}



export default React.memo(warningBoxTab);