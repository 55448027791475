import React from "react";
import Table from '../../Ui-widgets/Table'
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
// import SearchFilters from '../../Ui-widgets/SearchFilters'
import { SearchFilters } from '@wac-ui-dashboard/wac_component_library'


const LeadsPage1 = () => {
  const tableData = {
    columns: [
      { name: "Deal Name", field: "dealName", sortable: true, type: "link" },
      { name: "Service", field: "service", sortable: true },
      { name: "Client", field: "client", sortable: true, type: "link" },
      { name: "Deal Size", field: "dealSize", sortable: true },
      { name: "Expected Close ", field: "expectedClose", sortable: true },
      { name: "Notes", field: "notes", sortable: true },
      { name: "Stage", field: "stage", sortable: true },
    ],
    rows: [
      {
        dealName: { label: "Trikart App", link: "" },
        service: "Ecommerce",
        client: { label: "James", link: "" },
        dealSize: "$32,000",
        expectedClose: "17/3/2022",
        notes: "Lorem ipsum dolor sit amet, consectetur",
        stage: "Pending",
      },
      {
        dealName: { label: "Acme Corp", link: "" },
        service: "Dashboard",
        client: { label: "Charles", link: "" },
        dealSize: "$10,000",
        expectedClose: "17/3/2022",
        notes: "Nemo enim ipsam voluptatem",
        stage: "Pending",
      },
      {
        dealName: { label: "Al Qathami", link: "" },
        service: "Landing page",
        client: { label: "James", link: "" },
        dealSize: "$15,000",
        expectedClose: "17/3/2022",
        notes: "Quis autem vel eum iure reprehenderi",
        stage: "Pending",
      },
      {
        dealName: { label: "Landor", link: "" },
        service: "Flutter App",
        client: { label: "James", link: "" },
        dealSize: "$2,600",
        expectedClose: "17/3/2022",
        notes: "Autem reprehenderit",
        stage: "Pending",
      },
      {
        dealName: { label: "Trikart App", link: "" },
        service: "Ecommerce",
        client: { label: "James", link: "" },
        dealSize: "$4,000",
        expectedClose: "17/3/2022",
        notes: "Quis autem veleum reprehenderit",
        stage: "Pending",
      },
      {
        dealName: { label: "Marriott", link: "" },
        service: "Dashboard",
        client: { label: "Charles", link: "" },
        dealSize: "$7,000",
        expectedClose: "17/3/2022",
        notes: "Duis aute irure dolor",
        stage: "Pending",
      },
      {
        dealName: { label: "Al Qathami", link: "" },
        service: "Landing page",
        client: { label: "James", link: "" },
        dealSize: "$6,000",
        expectedClose: "17/3/2022",
        notes: "Neque porro quisquam",
        stage: "Pending",
      },
      {
        dealName: { label: "Landor", link: "" },
        service: "Flutter App",
        client: { label: "James", link: "" },
        dealSize: "$12,000",
        expectedClose: "17/3/2022",
        notes: "Excepteur sint occaecat cupidatat",
        stage: "Pending",
      },
      {
        dealName: { label: "Trikart App", link: "" },
        service: "Ecommerce",
        client: { label: "James", link: "" },
        dealSize: "$32,000",
        expectedClose: "17/3/2022",
        notes: "Lorem ipsum dolor sit amet, consectetur",
        stage: "Pending",
      },
      {
        dealName: { label: "Marriott", link: "" },
        service: "Dashboard",
        client: { label: "Charles", link: "" },
        dealSize: "$7,000",
        expectedClose: "17/3/2022",
        notes: "Duis aute irure dolor",
        stage: "Pending",
      },
      {
        dealName: { label: "Al Qathami", link: "" },
        service: "Landing page",
        client: { label: "James", link: "" },
        dealSize: "$6,000",
        expectedClose: "17/3/2022",
        notes: "Neque porro quisquam",
        stage: "Pending",
      },
      {
        dealName: { label: "Landor", link: "" },
        service: "Flutter App",
        client: { label: "James", link: "" },
        dealSize: "$12,000",
        expectedClose: "17/3/2022",
        notes: "Excepteur sint occaecat cupidatat",
        stage: "Pending",
      },
    ],
  };
  const enabledFilters = [
    { type: "search", isInFilters: false },
    {
      type: "buttonGroup",
      isInFilters: false,
      fields: [
        { label: "All", count: 50, isActive:true },
        { label: "Pending", count: 4 },
        { label: "Contacted", count: 10 },
        { label: "Proposal sent", count: 10 },
        { label: "No Response", count: 10 },
        { label: "Lost", count: 10 },
        { label: "Won", count: 10 },
      ],
    },
    { type: "date", isInFilters: false },
    { type: "filters", isInFilters: false },
    { type: "actions", isInFilters: false },
  ];
  return (
    <div>
      <h2 className="h1 pro-fw-bolder">Leads</h2>
      <SearchFilters filters={enabledFilters}></SearchFilters>
      <Table
        checkboxSelection
        rows={tableData.rows}
        columns={tableData.columns}
      ></Table>
      <div className="pro-flex-grow-1 pro-py-4 pro-d-flex pro-justify-center">
        <nav
          aria-label="Page navigation example pro-pagination"
          className="pro-mt-2"
        >
          <ul className="pro-pagination">
            <li className="page-item disabled">
              <a className="page-link" href="/" aria-label="Previous">
                <span aria-hidden="true">
                  <FaChevronLeft size={13} />
                </span>
              </a>
            </li>
            <li className="page-item active">
              <a className="page-link" href="/">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="/">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="/">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="/">
                4
              </a>
            </li>
            <li className="page-item">
              <span className="page-link" href="/">
                ...
              </span>
            </li>
            <li className="page-item">
              <a className="page-link" href="/">
                10
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="/" aria-label="Next">
                <span aria-hidden="true">
                  <FaChevronRight size={13} />
                </span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default LeadsPage1;
