import React from 'react'
import ProgressCircle from '../ProgressCircle'
import Style from './warningBox.module.scss'
function WarningBox({percentage, title, type, button}) {
  return (
    <div className={`${Style.root} pro-py-3 pro-px-4`}>
        {percentage && <ProgressCircle currentPercentage={percentage}/>}
        {title && <p className={`pro-fw-medium pro-mb-0 pro-px-2`}>Profile Incomplete</p>}
        {button && <button className={`pro-btn pro-btn-outline ${type === 'type-2' && `pro-ms-auto`}`}>{button.name}</button>}
    </div>
  )
}

export default WarningBox