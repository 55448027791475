import React from "react";
import Style from "./AvatarTab.module.scss";
import Highlight from "react-highlight";
import Assets from "../../Assets/Assets";
import { FaReact } from "react-icons/fa";
import { AvatarGroup, AvatarWithDetails } from '@wac-ui-dashboard/wac_component_library'

const avatarGroup = [
  { image: Assets.USER, name: "Jane Doe" },
  { image: Assets.USER, name: "Jane Doe" },
  { image: Assets.USER, name: "Jane Doe" },
  { image: Assets.USER, name: "Jane Doe" },
  { image: Assets.USER, name: "Jane Doe" },
  { image: Assets.USER, name: "Jane Doe" },
  { image: Assets.USER, name: "Jane Doe" },
];

const avatarWithDetails = {
  image: Assets.USER,
  name: "React",
  description: "Sharath",
  link:"/",
  color: "",
  icon: <FaReact />,
  colorClass: "department-react",
};

const code = `<div className='pro-avatar'>
  <img src="{image}" alt="" />
</div>

<div className='pro-avatar lg'>
  <img src="{image}" alt="" />
</div>

<div className='pro-avatar-group'>
  <div className='pro-avatar'>
    <img src="{image}" alt="" />
  </div>
  <div className='pro-avatar'>
    <img src="{image}" alt="" />
  </div>
  <div className='pro-avatar'>
    <img src="{image}" alt="" />
  </div>
  <div className='pro-avatar'>
    <img src="{image}" alt="" />
  </div>
  <div className='pro-avatar'>
    <img src="{image}" alt="" />
  </div>
  <div className="pro-remain">
    <div className="pro-avatar remain">
      <span>+3</span>
    </div>
  </div>
</div>

<div className="pro-remain">
  <div className="pro-avatar remain">
    <span>+3</span>
  </div>
  <div className="pro-menu">
    <ul className="pro-menu-list">
      <li className="pro-menu-item">
        <div className="pro-avatar">
          <img src="{image}" alt=""/>
        </div>
        <span>Amar Sundaram</span>
      </li>
      <li className="pro-menu-item">
        <div className="pro-avatar">
          <img src="{image}" alt=""/>
        </div>
        <span>Annika Rangarajan</span>
      </li>
      <li className="pro-menu-item">
        <div className="pro-avatar">
          <img src="{image}" alt=""/>
        </div>
        <span>Molly Clark</span>
      </li>
    </ul>
  </div>
</div>

`;

function AvatarTab() {
  return (
    <div className={Style.buttonWrapper}>
      <div className={"text bg-primary"}>
        <h3 className={`pro-ttl h3 pro-mb-3`}>Avatar</h3>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi,
          asperiores tenetur animi alias fuga provident deleniti praesentium
          tempore debitis accusamus impedit? Quisquam quas vel aliquam,
          molestias id reprehenderit ipsum neque! Lorem, ipsum dolor sit amet
          consectetur adipisicing elit. Excepturi, asperiores tenetur animi
          alias fuga provident deleniti praesentium tempore debitis accusamus
          impedit? Quisquam quas vel aliquam, molestias id reprehenderit ipsum
          neque!
        </p>
      </div>

      <div className={"component input"}>
        <div className={"items"}>Single</div>
        <div className={"items"}>
          <div className="pro-avatar">
            <img
              src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
              alt=""
            />
          </div>
        </div>
        <div className={"items"}>
          <div className="pro-avatar lg">
            <img
              src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="items">
          <AvatarWithDetails avatarData={avatarWithDetails}></AvatarWithDetails>
        </div>
      </div>
      <div className="component input">
        <div className={"items"}>Multiple</div>
        <div className={"items"}>
          <div className="pro-avatar-group">
            <div className="pro-avatar">
              <img
                src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                alt=""
              />
            </div>
            <div className="pro-avatar">
              <img
                src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                alt=""
              />
            </div>
            <div className="pro-avatar">
              <img
                src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                alt=""
              />
            </div>
            <div className="pro-avatar">
              <img
                src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                alt=""
              />
            </div>
            <div className="pro-avatar">
              <img
                src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className={"items"}>
          <div className="pro-avatar-group">
            <div className="pro-avatar lg">
              <img
                src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                alt=""
              />
            </div>
            <div className="pro-avatar lg">
              <img
                src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                alt=""
              />
            </div>
            <div className="pro-avatar lg">
              <img
                src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                alt=""
              />
            </div>
            <div className="pro-avatar lg">
              <img
                src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                alt=""
              />
            </div>
            <div className="pro-avatar lg">
              <img
                src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className={"component input"}>
        <div className={"items"}>Group with remaining</div>
        <div className={"items"}>
          <div className="pro-avatar-group">
            <div className="pro-avatar">
              <img
                src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                alt=""
              />
            </div>
            <div className="pro-avatar">
              <img
                src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                alt=""
              />
            </div>
            <div className="pro-avatar">
              <img
                src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                alt=""
              />
            </div>
            <div className="pro-avatar">
              <img
                src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                alt=""
              />
            </div>
            <div className="pro-avatar">
              <img
                src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                alt=""
              />
            </div>
            <div className="pro-remain">
              <div className="pro-avatar remain">
                <span>+3</span>
              </div>
            </div>
          </div>
        </div>
        <div className={"items"}>
          <div className="pro-avatar-group">
            <div className="pro-avatar lg">
              <img
                src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                alt=""
              />
            </div>
            <div className="pro-avatar lg">
              <img
                src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                alt=""
              />
            </div>
            <div className="pro-avatar lg">
              <img
                src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                alt=""
              />
            </div>
            <div className="pro-avatar lg">
              <img
                src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                alt=""
              />
            </div>
            <div className="pro-avatar lg">
              <img
                src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                alt=""
              />
            </div>
            <div className="pro-remain">
              <div className="pro-avatar lg remain">
                <span>+3</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={"component input"}>
        <div className="items">Remanining</div>
        <div className={"items"}>
          <div className="pro-remain">
            <div className="pro-avatar remain">
              <span>+3</span>
            </div>
          </div>
        </div>
        <div className="items">
          <div className="pro-remain">
            <div className="pro-avatar remain lg">
              <span>+3</span>
            </div>
          </div>
        </div>
      </div>

      <div className={"component input"}>
        <div className="items">On hover</div>
        <div className={"items"}>
          <div className="pro-remain">
            <div className="pro-avatar remain">
              <span>+3</span>
            </div>
            <div className="pro-menu">
              <ul className="pro-menu-list">
                <li className="pro-menu-item">
                  <div className="pro-avatar">
                    <img
                      alt=""
                      src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                    />
                  </div>
                  <span>Amar Sundaram</span>
                </li>
                <li className="pro-menu-item">
                  <div className="pro-avatar">
                    <img
                      alt=""
                      src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                    />
                  </div>
                  <span>Annika Rangarajan</span>
                </li>
                <li className="pro-menu-item">
                  <div className="pro-avatar">
                    <img
                      alt=""
                      src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                    />
                  </div>
                  <span>Molly Clark</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={"items"}>
          <div className="pro-remain">
            <div className="pro-avatar remain lg">
              <span>+3</span>
            </div>
            <div className="pro-menu">
              <ul className="pro-menu-list">
                <li className="pro-menu-item">
                  <div className="pro-avatar">
                    <img
                      alt=""
                      src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                    />
                  </div>
                  <span>Amar Sundaram</span>
                </li>
                <li className="pro-menu-item">
                  <div className="pro-avatar">
                    <img
                      alt=""
                      src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                    />
                  </div>
                  <span>Annika Rangarajan</span>
                </li>
                <li className="pro-menu-item">
                  <div className="pro-avatar">
                    <img
                      alt=""
                      src="https://expertphotography.b-cdn.net/wp-content/uploads/2020/08/social-media-profile-photos-3.jpg"
                    />
                  </div>
                  <span>Molly Clark</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="items">
          <AvatarGroup avatars={avatarGroup}></AvatarGroup>
        </div>
      </div>

      <div className="code-area">
        <Highlight>{code}</Highlight>
      </div>
    </div>
  );
}

export default React.memo(AvatarTab);
