import React from "react";
import Style from "./PaginationTab.module.scss";
import Highlight from "react-highlight";
import Select from "react-select";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const options = [
  { value: "20 per page", label: "20 per page" },
  { value: "30 per page", label: "30 per page" },
  { value: "40 per page", label: "40 per page" },
];



const code = `import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

<nav aria-label="Page navigation example pro-pagination">
  <ul className="pro-pagination">
    <li className="page-item disabled">
      <a className="page-link" href="/" aria-label="Previous">
        <span aria-hidden="true">
          <FaChevronLeft size={13} />
        </span>
      </a>
    </li>
    <li className="page-item active">
      <a className="page-link" href="/">
        1
      </a>
    </li>
    <li className="page-item">
      <a className="page-link" href="/">
        2
      </a>
    </li>
    <li className="page-item">
      <a className="page-link" href="/">
        3
      </a>
    </li>
    <li className="page-item">
      <a className="page-link" href="/">
        4
      </a>
    </li>
    <li className="page-item">
      <span className="page-link" href="/">
        ...
      </span>
    </li>
    <li className="page-item">
      <a className="page-link" href="/">
        10
      </a>
    </li>
    <li className="page-item">
      <a className="page-link" href="/" aria-label="Next">
        <span aria-hidden="true">
          <FaChevronRight size={13} />
        </span>
      </a>
    </li>
  </ul>
</nav>`;

function PaginationTab() {
  return (
    <div className={Style.buttonWrapper}>
      <div className={"text"}>
        <h3 className={`pro-ttl h3 pro-mb-3`}>Pagination</h3>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi,
          asperiores tenetur animi alias fuga provident deleniti praesentium
          tempore debitis accusamus impedit? Quisquam quas vel aliquam,
          molestias id reprehenderit ipsum neque! Lorem, ipsum dolor sit amet
          consectetur adipisicing elit. Excepturi, asperiores tenetur animi
          alias fuga provident deleniti praesentium tempore debitis accusamus
          impedit? Quisquam quas vel aliquam, molestias id reprehenderit ipsum
          neque!
        </p>
      </div>

      <div className={"component"}>
        <div className="items pro-w-100">
          <nav aria-label="Page navigation example pro-pagination">
            <ul className="pro-pagination">
              <li className="page-item disabled">
                <a className="page-link" href="/" aria-label="Previous">
                  <span aria-hidden="true">
                    <FaChevronLeft size={13} />
                  </span>
                </a>
              </li>
              <li className="page-item active">
                <a className="page-link" href="/">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="/">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="/">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="/">
                  4
                </a>
              </li>
              <li className="page-item">
                <span className="page-link" href="/">
                  ...
                </span>
              </li>
              <li className="page-item">
                <a className="page-link" href="/">
                  10
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="/" aria-label="Next">
                  <span aria-hidden="true">
                    <FaChevronRight size={13} />
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="items pro-w-100">
          <nav aria-label="Page navigation example pro-pagination">
            <ul className="pro-pagination">
              <li className="page-item disabled">
                <a className="page-link" href="/" aria-label="Previous">
                  <span aria-hidden="true">
                    <FaChevronLeft size={13} />
                  </span>
                </a>
              </li>
              <li className="page-item active">
                <a className="page-link" href="/">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="/">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="/">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="/">
                  4
                </a>
              </li>
              <li className="page-item">
                <span className="page-link" href="/">
                  ...
                </span>
              </li>
              <li className="page-item">
                <a className="page-link" href="/">
                  10
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="/" aria-label="Next">
                  <span aria-hidden="true">
                    <FaChevronRight size={13} />
                  </span>
                </a>
              </li>
              <li>
                <Select
                  options={options}
                  className={"pro-input "}
                  classNamePrefix="pro-input"
                  defaultValue={options[0]}
                  id={3}
                />
              </li>
            </ul>

          </nav>
        </div>
      </div>

      <div className="code-area">
        <Highlight>{code}</Highlight>
      </div>
    </div>
  );
}

export default React.memo(PaginationTab);
