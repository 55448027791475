import React from "react";
import Layout from "../../Ui-components/Layout";
import {Table } from '@wac-ui-dashboard/wac_component_library';

const Toc = () => {
  const tableData = {
    columns: [
      { name: "Sl.No", field: "no", sortable: false },
      { name: "Page", field: "page", sortable: false },
      { name: "Link", field: "link", type:"link", sortable: false },
    ],
    rows: [
      {
        no: "1",
        page: "Style Guide",
        link: {label:"Style Guide",link:"/"},
      },
      {
        no: "2",
        page: "Leads page",
        link: {label:"Leads",link:"/leads"},
      },
      {
        no: "3",
        page: "Appearance Page",
        link: {label:"Appearance",link:"/appearance"},
      },
      {
        no: "4",
        page: "Profile Page",
        link: {label:"profile",link:"/profile-over-view"},
      },
    ],
  };
  return (
    <Layout>
      <div className="pro-container-inner">
        <div className="pro-page-wrp">
          <Table rows={tableData.rows} columns={tableData.columns}></Table>
        </div>
      </div>
    </Layout>
  );
};

export default Toc;
