import React from 'react'
import context from '../../Ui-components/context';
import Preference from '../../Ui-widgets/Preference';

function PreferenceTab() {
  const { dataPreference } = context
  return (
    <div><Preference EditBtnIcon={<span class="material-symbols-outlined"> edit_square </span>} title={`Partner Preferences`} data={dataPreference}/></div>
  )
}

export default React.memo(PreferenceTab);