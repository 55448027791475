import React from 'react'
import Style from  './ProgressCircle.module.scss'
const LINE_RADIUS = 113;
function ProgressCircle({currentPercentage}) {

  const percentage = currentPercentage || 20;
  const getPercentage = (percentage /100)* LINE_RADIUS
  const lineProgress =  LINE_RADIUS + getPercentage;

  return (
    <div className={`${Style.root}`}>
      {
        currentPercentage && 
        <>
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
          <g id="Ellipse_1" dataname="Ellipse 1" fill="none" stroke="#ffffff" strokeWidth="3">
            <circle cx="20" cy="20" r="18" fill="none" style={{strokeDasharray : `${lineProgress}px`}}/>
          </g>
          </svg>

          <p className={`pro-font-xs pro-mb-0`}>
            {`${percentage}%`}
          </p> 
        </>
      }
      

    </div>
  )
}

export default ProgressCircle