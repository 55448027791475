import React from 'react'
import Style from './multiColumnTable.module.scss'
function MultiColumnTable({extraClassNames, title, editIcon, handleEdit, data, minWidth}) {

  return (
    <div className={`${Style.root} ${extraClassNames}`}>

       <div className={`${Style.root_header} pro-pb-4`}>
        {title && <h6 className="pro-ttl h6 pro-mb-0">{title}</h6>}
        {editIcon && <button className={`${Style.root_Icon} pro-btn pro-p-2 border-rounded`}>{editIcon}</button>}
       </div>

        <div className={`${Style.root_body} pro-pb-0`}>
        <table className="pro-table no-border">
            <tbody>
            {data && data.map(item => {
                return(
                    <tr>
                    <th width={minWidth || `auto`}><p className={`pro-font-sm pro-mb-0`}>{item.heading}</p></th>
                    <td><p className={`pro-font-sm pro-mb-0`}>{item.data}</p></td>
                    </tr>
                )
            })}
            </tbody>
        </table>
            
        </div>


    </div>
  )
}

export default MultiColumnTable