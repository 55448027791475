import React from 'react'
import Style from './mediaActions.module.scss'

function MediaActions({ data }) {
    
    return (
        <div className={Style.root}>
            <ul>
                {data ? data.map(item => {
                    return (
                        <li key={item.id}>
                            <button className={`pro-btn-link dark pro-items-center pro-p-0 pro-py-4`}>
                                {item?.icon}
                                <span><p className={`pro-fw-bold pro-mb-0 pro-ms-1`}>{item?.title}</p></span>
                            </button>
                        </li>)
                }) : 'add data'} 
            </ul>
        </div>
    )
}

export default MediaActions