import React from "react";
import Style from "./SpinnerTab.module.scss";
import Highlight from "react-highlight";

const code = `<div className="pro-spinner"></div>
<div className="pro-spinner lg"></div>`;

const SpinnerTab = () => {
  return (
    <div className={Style.buttonWrapper}>
      <div className={"text bg-primary"}>
        <h3 className={`pro-ttl h3 pro-mb-3`}>Spinner</h3>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi,
          asperiores tenetur animi alias fuga provident deleniti praesentium
          tempore debitis accusamus impedit? Quisquam quas vel aliquam,
          molestias id reprehenderit ipsum neque! Lorem, ipsum dolor sit amet
          consectetur adipisicing elit. Excepturi, asperiores tenetur animi
          alias fuga provident deleniti praesentium tempore debitis accusamus
          impedit? Quisquam quas vel aliquam, molestias id reprehenderit ipsum
          neque!
        </p>
      </div>

      <div className={"component input"}>
        <div className={"items"}>Spinner small (18px)</div>
          <div className="pro-spinner"></div>
      </div>
      <div className={"component input"}>
        <div className={"items"}>Spinner large (36px)</div>
        <div className={"items"}>
          <div className="pro-spinner lg"></div>
        </div>
      </div>

      <div className="code-area">
        <Highlight>{code}</Highlight>
      </div>
    </div>
  );
};

export default React.memo(SpinnerTab);
