const initialComponents = [
    {id : 1, title: 'button', link : ''},
    {id : 2, title: 'Text', link : ''},
    {id : 3, title: 'Dropdowns', link : ''},
    {id : 4, title: 'Input Box', link : ''},
    // {id : 5, title: 'button', link : ''},
    // {id : 6, title: 'button', link : ''},
    // {id : 7, title: 'button', link : ''},
]


const dataPreference = [
    {id: 1, title : 'Never married'},
    {id: 2, title : 'Never married'},
    {id: 3, title : 'Never married'},
    {id: 4, title : 'Never married'},
    {id: 5, title : 'Never married'},
    {id: 6, title : 'Never married'},
    {id: 7, title : 'Never married'},
    {id: 8, title : 'Never married'},
    {id: 9, title : 'Never married'}
  ]


  const datachoose = [
    {id: 1, title : 'Name'},
    {id: 2, title : 'Mobile'},
    {id: 3, title : 'User ID'},
    {id: 4, title : 'Status'}
  ]

const context = {
    initialComponents,
    dataPreference,
    datachoose
}

export default context