import React from 'react'
import Style from './simpleReportText.module.scss'
function SimpleReportText() {
  return (
    <div className={Style.root}>
      <div className={Style.textWrapper}>
        <div className={`${Style.textInner} col-12`}>
          <div className='row'>
            <div className={`${Style.item} col`}>
              <p className="pro-fw-medium pro-mb-1">Today</p>
              <h5 className="pro-ttl h5 pro-mb-0">12</h5>
            </div>
            <div className={`${Style.item} col`}>
              <p className="pro-fw-medium pro-mb-1">This week</p>
              <h5 className="pro-ttl h5 pro-mb-0">50</h5>
            </div>
            <div className={`${Style.item} col`}>
              <p className="pro-fw-medium pro-mb-1">This Month</p>
              <h5 className="pro-ttl h5 pro-mb-0">90</h5>
            </div>
            <div className={`${Style.item} col`}>
              <p className="pro-fw-medium pro-mb-1">All time</p>
              <h5 className="pro-ttl h5 pro-mb-0">180</h5>
            </div>
          </div>
        </div>
      </div>
      <div className={Style.actionWrapper}>
        <a href="/" className="pro-btn pro-btn-outline"><span>Primary</span></a>
      </div>
    </div>
  )
}

export default SimpleReportText