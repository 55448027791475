import { HeadingGroup, Image } from '@wac-ui-dashboard/wac_component_library'
import React from 'react'
import Assets from '../../Assets/Assets'

export default function Appearance() {
  return (
    <div className={`col-12 pro-p-5`}>
        <div className={`row`}>
            <div className={`col-12 pro-mb-5`}>
                <HeadingGroup title={`Appearance`}/>
            </div>
            <div className={`col-12 pro-mt-4`}>
                <div className={`row`}>
                    <div className={`col-12 col-lg-3 pro-pt-5`}>
                        <h6 class="pro-ttl h6 pro-mb-1">Theme</h6>
                        <p class="pro-fw-regular pro-mb-0">Duis aute irure reprehenderit</p>
                    </div>
                    <div className={`col-12 col-lg-9 pro-my-5`}>
                       <div className="row">
                            <div className="col-6 col-sm-4 col-lg-2">
                                <div className={`pro-w-100`}>
                                        <Image src={Assets.image_appearance} width={180} height={118}/>
                                        <p class="pro-font-sm pro-fw-medium pro-mb-0 pro-mt-2">Light</p>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4 col-lg-2">
                                <div className={`pro-w-100`}>
                                        <Image src={Assets.image_appearance} width={180} height={118}/>
                                        <p class="pro-font-sm pro-fw-medium pro-mb-0 pro-mt-2">Dark</p>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4 col-lg-2">
                                <div className={`pro-w-100`}>
                                        <Image src={Assets.image_appearance} width={180} height={118}/>
                                        <p class="pro-font-sm pro-fw-medium pro-mb-0 pro-mt-2">Black</p>
                                </div>
                            </div>
                       </div>
                    </div>
                </div>
                <hr  className={`pro-my-4`}/>
                <div className={`row`}>
                    <div className={`col-12 col-lg-3 pro-pt-5`}>
                        <h6 class="pro-ttl h6 pro-mb-1">Table view</h6>
                        <p class="pro-fw-regular pro-mb-0">Duis aute irure reprehenderit</p>
                    </div>
                    <div className={`col-12 col-lg-9 pro-my-5`}>
                       <div className="row">
                            <div className="col-6 col-sm-4 col-lg-2">
                                <div className={`pro-w-100`}>
                                        <Image src={Assets.image_appearance} width={180} height={118}/>
                                        <p class="pro-font-sm pro-fw-medium pro-mb-0 pro-mt-2">Light</p>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4 col-lg-2">
                                <div className={`pro-w-100`}>
                                        <Image src={Assets.image_appearance} width={180} height={118}/>
                                        <p class="pro-font-sm pro-fw-medium pro-mb-0 pro-mt-2">Light</p>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4 col-lg-2">
                                <div className={`pro-w-100`}>
                                        <Image src={Assets.image_appearance} width={180} height={118}/>
                                        <p class="pro-font-sm pro-fw-medium pro-mb-0 pro-mt-2">Light</p>
                                </div>
                            </div>
                       </div>
                    </div>
                </div>
                <hr  className={`pro-my-4`}/>
                <div className={`row`}>
                    <div className={`col-12 col-lg-3 pro-pt-5`}>
                        <h6 class="pro-ttl h6 pro-mb-1">Side menu</h6>
                        <p class="pro-fw-regular pro-mb-0">Duis aute irure reprehenderit</p>
                    </div>
                    <div className={`col-12 col-lg-9 pro-my-5`}>
                       <div className="row">
                            <div className="col-6 col-sm-4 col-lg-2">
                                <div className={`pro-w-100`}>
                                        <Image src={Assets.image_appearance} width={180} height={118}/>
                                        <p class="pro-font-sm pro-fw-medium pro-mb-0 pro-mt-2">Light</p>
                                </div>
                            </div>
                            <div className="col-6 col-sm-4 col-lg-2">
                                <div className={`pro-w-100`}>
                                        <Image src={Assets.image_appearance} width={180} height={118}/>
                                        <p class="pro-font-sm pro-fw-medium pro-mb-0 pro-mt-2">Light</p>
                                </div>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
