import React from 'react'
import BulkEdit from '../../Ui-widgets/BulkEdit'
import Style from './bulkEditTab.module.scss'
const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
function BulkEditTab() {
  return (
    <div className={Style.root}>
        <BulkEdit title={`Bulk edit 2 records`} options={options}/>
    </div>
  )
}

export default BulkEditTab