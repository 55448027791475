import LOGO from "./images/header/logo.svg";
import USER from "./images/header/avatar.png";
import DrawerIcon1 from "./images/drawer-icon1.svg";
import DrawerIcon2 from "./images/drawer-icon2.svg";
import DrawerIcon3 from "./images/drawer-icon3.svg";
import image_01 from "./images/card-image/image-01.jpg";
import user_image from "./images/user-image.png";
import image_appearance from "./images/image-appearance.png";

const Assets = {
    LOGO,
    USER,
    DrawerIcon1,
    DrawerIcon2,
    DrawerIcon3,
    image_01,
    user_image,
    image_appearance
}

export default Assets;