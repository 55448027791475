import React, { useState } from 'react';
import Style from './simpleImageSlider.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Image } from '@wac-ui-dashboard/wac_component_library';
function SimpleImageSlider({data, extraClassName, width, height}) {
    const [counter, setCounter] = useState(1)
  return (
    <div className={`${Style.root} ${extraClassName}`}>
        <Swiper
            spaceBetween={0}
            slidesPerView={1}
            onSlideChange={(swiper) => {
                setCounter(swiper?.activeIndex + 1);
            }}>
            {data && data.map(item => {
                return(
                    <SwiperSlide key={item?.id}>
                        <Image 
                            src={item?.image}
                            width={329.7} 
                            height={329.55}/>
                    </SwiperSlide>
                )
            })}
            
            
        </Swiper>
    {data && <div className={Style.counter}>
       <span>{counter}/{data?.length}</span>
    </div>}
    </div>
  )
}

export default SimpleImageSlider