import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { DateRangePicker, Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import { IoCalendarOutline } from "react-icons/io5";
import Style from "./DatePickerWithInput.module.scss";

const DatePickerWithInput = ({
  range,
  initialValue,
  disabledDates,
  disabledDay,
  minDate,
  maxDate,
  onDateChange,
}) => {
  const formatDateRange = (date) => {
    if (date) {
      if (range) {
        let startDate = format(date.startDate, "MMM dd, yyyy");
        let endDate = format(date.endDate, "MMM dd, yyyy");
        return `${startDate} - ${endDate}`;
      } else {
        return format(date, "MMM dd, yyyy");
      }
    }
  };
  const [dateRange, setDateRange] = useState(initialValue);

  const [dateRangeShow, setDateRangeShow] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState(
    formatDateRange(dateRange)
  );
  const dateRangeCancel = () => {
    setDateRangeShow(false);
  };
  const dateRangeApply = () => {
    setDateRangeShow(false);
    onDateChange && onDateChange(dateRange);
    setDateRangeValue(formatDateRange(dateRange));
  };
  const dateApply = (date) => {
    setDateRange(date);
    setDateRangeShow(false);
    onDateChange && onDateChange(date);
    setDateRangeValue(formatDateRange(date));
    return 
  };

  return (
    <div>
      <Dropdown
        show={dateRangeShow}
        onToggle={() => setDateRangeShow(!dateRangeShow)}
      >
        <Dropdown.Toggle
          as={"div"}
          title="Date range dropdown"
          className={Style.dateRangeToggle}
        >
          <div className="input-wrap">
            <input
              type="text"
              onChange={e => setDateRangeValue(e.target.value)}
              value={dateRangeValue}
              placeholder="Select date"
              className={`pro-input icon-l lg ${Style.date_range_input}`}
            />
            <span className="pro-icon">
              <IoCalendarOutline size={16} />
            </span>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="pro-py-0">
          {range ? (
            <>
              <DateRangePicker
                months={2}
                minDate={minDate}
                maxDate={maxDate}
                ranges={[dateRange]}
                direction="horizontal"
                showDateDisplay={false}
                disabledDay={disabledDay}
                disabledDates={disabledDates}
                monthDisplayFormat="MMMM yyyy"
                showMonthAndYearPickers={false}
                moveRangeOnFirstSelection={false}
                onChange={(item) => setDateRange(item.selection)}
              />
              <div className="pro-d-flex date-range-buttons pro-gap-1">
                <button
                  className="pro-btn pro-btn-primary"
                  onClick={dateRangeApply}
                >
                  Apply
                </button>
                <button
                  className="pro-btn pro-btn-primary"
                  onClick={dateRangeCancel}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : (
            <Calendar
              date={dateRange}
              minDate={minDate}
              maxDate={maxDate}
              disabledDay={disabledDay}
              disabledDates={disabledDates}
              onChange={(item) => dateApply(item)}
            />
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DatePickerWithInput;
