import Toc from "./Pages/Toc";
import HomePage from "./Pages/HomePage";
import LeadsPage from "./Pages/LeadsPage";
import LeadsPage1 from "./Pages/LeadsPage1";
import LeadsPage2 from "./Pages/LeadsPage2";
import LeadsPage3 from "./Pages/LeadsPage3";
import OverviewPage from "./Pages/OverviewPage";
import TasksPage from "./Pages/TasksPage";
import ReportsPage from "./Pages/ReportsPage";
import TasksPage1 from "./Pages/TasksPage1";
import TasksPage2 from "./Pages/TasksPage2";
import TasksPage3 from "./Pages/TasksPage3";
import ProfilePageOverview from "./Pages/ProfilePageOverview";
import Appearance from "./Pages/Appearance";

const routes = [
  { path: "/", element: <HomePage /> },
  { path: "/profile-over-view", element: <ProfilePageOverview /> },
  { path: "/matches", element: <ProfilePageOverview /> },
  {
    path: "/leads",
    element: <LeadsPage />,
    childRoutes: [
      { isIndex: true, element: <LeadsPage1 /> },
      { path: "leads2", element: <LeadsPage2 /> },
      { path: "leads3", element: <LeadsPage3 /> },
    ],
  },
  { path: "/overview", element: <OverviewPage /> },
  {
    path: "/tasks",
    element: <TasksPage />,
    childRoutes: [
      { isIndex: true, element: <TasksPage1 /> },
      { path: "/tasks/task2", element: <TasksPage2 /> },
      { path: "/tasks/task3", element: <TasksPage3 /> },
    ],
  },
  { path: "/report", element: <ReportsPage /> },
  { path: "/toc", element: <Toc /> },
  { path: "/appearance", element: <Appearance /> },
];

export default routes;
