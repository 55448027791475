import React from 'react'
import PackageRule from '../PackageRule'
import Style from './createPackage.module.scss'

function CreatePackage({title}) {
    return (
        <div className={Style.root}>

            <h3 className="pro-ttl h3 pro-mb-4">{title}</h3>


            
            <div className='col-12 pro-mb-4'>
                <div className="row">
                    <div className="col-lg-4 pro-mb-4">
                        <div className="input-wrap">
                            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Photo</label>
                            <input
                                type="text"
                                placeholder="placeholder"
                                className="pro-input lg"
                            />
                        </div>
                    </div>
                    <div className="col-lg-4 pro-mb-4">
                        <div className="input-wrap">
                            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Photo</label>
                            <input
                                type="text"
                                placeholder="placeholder"
                                className="pro-input lg"
                            />
                        </div>
                    </div>
                    <div className="col-lg-4 pro-mb-4">
                        <div className="input-wrap">
                            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Photo</label>
                            <input
                                type="text"
                                placeholder="placeholder"
                                className="pro-input lg"
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="input-wrap">
                            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Photo</label>
                            <textarea rows={6}
                                type="text"
                                placeholder="placeholder"
                                className="pro-input lg"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <PackageRule title={`Rules`}/>

            <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
                    <button className="pro-btn-link lg pro-px-5">
                        <span>cancel</span>
                    </button>
                    <button className="pro-btn pro-btn-primary lg">
                        <span>Create</span>
                    </button>
                </div>




            

        </div>
    )
}

export default CreatePackage