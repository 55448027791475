import React from 'react'
import Style from './simpleTextDetails.module.scss'
function SimpleTextDetails({title, designation, uId, isOnline, lastOnline, extraClassNames}) {
  return (
    <div className={`${Style.root} ${extraClassNames}`}>
        {title && <h4 className="pro-ttl h4 pro-mb-0">{title}</h4>}
        {designation && <p className="pro-fw-regular pro-mb-5">{designation}</p>}
        {lastOnline || uId ? <p className="pro-fw-regular pro-mb-0"><span>{uId}</span>{isOnline && <span className={Style.online}></span> }<span>{lastOnline}</span></p>: null}
    </div>
  )
}

export default SimpleTextDetails