import React from 'react';
import Style from './actionBtnsTab.module.scss'
import { Auth } from '@wac-ui-dashboard/wac_component_library'
import ActionBtns from '../../Ui-widgets/ActionBtns';


function ActionBtnsTab() {
    return (
        <div className={Style.root}>
            <ActionBtns submitText={'create'} cancelText={`cancel`} isRight/>.
            <div className={`pro-p-2`}></div>
            <ActionBtns submitText={'create'} cancelText={`cancel`} />
        </div>
    )
}



export default React.memo(ActionBtnsTab);