import React from 'react';
import Highlight from 'react-highlight';
import Style from './memberRegistration.module.scss';
import { MemberRegistration } from '@wac-ui-dashboard/wac_component_library';
function MemberRegistrationTab() {
    const code = `
    <div className={\`\${Style.root} pro-py-3 pro-px-4\`}>
    <div className={\`\${Style.root_inner}\`}>

        <div className={\`\${Style.root_progress} pro-pb-4\`}>
            <div className={\`\${Style.root_progress_inner}\`}>

                <div className={\`\${Style.root_items}\`}>
                    <div className={\`\${Style.item} \${Style.active} pro-p-2\`}>
                        <p className={\`pro-font-xs pro-fw-medium pro-mb-2\`}>Basic</p>
                        <span className={Style.item_progress_container}>
                            <span className={Style.item_progress_bar}></span>
                        </span>
                    </div>
                </div>

                <div className={\`\${Style.root_items}\`}>

                    <div className={\`\${Style.item} pro-p-2\`}>
                        <p className={\`pro-font-xs pro-fw-medium pro-mb-2\`}>Com</p>
                        <span className={Style.item_progress_container}>
                            <span className={Style.item_progress_bar}></span>
                        </span>
                    </div>
                </div>

                <div className={\`\${Style.root_items}\`}>
                    <div className={\`\${Style.item} pro-p-2\`}>
                        <p className={\`pro-font-xs pro-fw-medium pro-mb-2\`}>Emp</p>
                        <span className={Style.item_progress_container}>
                            <span className={Style.item_progress_bar}></span>
                        </span>
                    </div>
                </div>

                <div className={\`\${Style.root_items}\`}>
                    <div className={\`\${Style.item} pro-p-2\`}>
                        <p className={\`pro-font-xs pro-fw-medium pro-mb-2\`}>Edu</p>
                        <span className={Style.item_progress_container}>
                            <span className={Style.item_progress_bar}></span>
                        </span>
                    </div>
                </div>

                <div className={\`\${Style.root_items}\`}>
                    <div className={\`\${Style.item} pro-p-2\`}>
                        <p className={\`pro-font-xs pro-fw-medium pro-mb-2\`}>Log</p>
                        <span className={Style.item_progress_container}>
                            <span className={Style.item_progress_bar}></span>
                        </span>
                    </div>
                </div>

              


            </div>
        </div>




        <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Name</label>
            <input
                type="text"
                // placeholder="placeholder"
                className="pro-input lg"
            />
        </div>
        <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Gender</label>
            <input
                type="text"
                // placeholder="placeholder"
                className="pro-input lg"
            />
        </div>
        <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Community</label>
            <input
                type="text"
                // placeholder="placeholder"
                className="pro-input lg"
            />
        </div>
        <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Diocese</label>
            <input
                type="text"
                // placeholder="placeholder"
                className="pro-input lg"
            />
        </div>
        <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Parish</label>
            <input
                type="text"
                // placeholder="placeholder"
                className="pro-input lg"
            />
        </div>
        <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Family</label>
            <input
                type="text"
                // placeholder="placeholder"
                className="pro-input lg"
            />
        </div>
        <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Date of Birth</label>
            <input
                type="text"
                // placeholder="placeholder"
                className="pro-input lg"
            />
        </div>
        <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Baptism Name</label>
            <input
                type="text"
                // placeholder="placeholder"
                className="pro-input lg"
            />
        </div>
        <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Name of Father</label>
            <input
                type="text"
                // placeholder="placeholder"
                className="pro-input lg"
            />
        </div>
        <div className="input-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">Name of Mother</label>
            <input
                type="text"
                // placeholder="placeholder"
                className="pro-input lg"
            />
        </div>


    </div>


</div>
`;
    return (

        <div className={Style.buttonWrapper}>
            <div className={"text"}>
                <h3  className={`pro-ttl h3 pro-mb-3`}>Member Registration</h3>
                <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi,
                    asperiores tenetur animi alias fuga provident deleniti praesentium
                    tempore debitis accusamus impedit? Quisquam quas vel aliquam,
                    molestias id reprehenderit ipsum neque! Lorem, ipsum dolor sit amet
                    consectetur adipisicing elit. Excepturi, asperiores tenetur animi
                    alias fuga provident deleniti praesentium tempore debitis accusamus
                    impedit? Quisquam quas vel aliquam, molestias id reprehenderit ipsum
                    neque!
                </p>
            </div>
            <div className='col-12'>
                <MemberRegistration />
            </div>

            <div className="code-area">
        <Highlight>{code}</Highlight>
      </div>
        </div>

    )
}



export default React.memo(MemberRegistrationTab);