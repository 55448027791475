import React from 'react'
import Style from './paymentHistory.module.scss'
function PaymentHistory({icon}) {
  return (
    <div className={`${Style.root} pro-d-flex pro-pb-4 pro-mb-4`}>
        <div className={Style.root_icon}>
            {icon}
        </div>
        <div className={`${Style.root_text} pro-d-flex pro-ps-4`}>
            <div className='col'>
                <h6 className={`pro-ttl h6 pro-mb-2`}>Platinum</h6>
                <p className={`pro-mb-0 pro-fw-regular`}>02-01-2023, 10:30 AM</p>
            </div>
            <div className='col'>
                <h6 className={`pro-ttl h6 pro-mb-2 pro-fw-medium pro-text-end`}>₹ 2000</h6>
                <p className={`pro-mb-0 pro-fw-regular pro-text-end`}>UPI Payment</p>
            </div>
        </div>
    </div>
  )
}

export default PaymentHistory