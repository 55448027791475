import React from 'react'
import Home from '../../Ui-components/Home/Index'
import Layout from '../../Ui-components/Layout'


function HomePage() {
  return (
    <Layout>
        <Home/>
    </Layout>
  )
}

export default HomePage