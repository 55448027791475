import React from "react";
import Style from "./BadgesTab.module.scss";
import Highlight from "react-highlight";

const code = `<div className="pro-badge badge-danger-outline">Due</div>
<div className="pro-badge badge-grey-outline">2/3 Remaining</div>
<div className="pro-badge badge-success-outline">Complete</div>
<div className="pro-badge badge-danger">High</div>
<div className="pro-badge badge-grey">Tags / Category</div>`;

const BadgesTab = () => {
  return (
    <div className={Style.buttonWrapper}>
      <div className={"text bg-primary"}>
        <h3 className={`pro-ttl h3 pro-mb-3`}>Badges</h3>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi,
          asperiores tenetur animi alias fuga provident deleniti praesentium
          tempore debitis accusamus impedit? Quisquam quas vel aliquam,
          molestias id reprehenderit ipsum neque! Lorem, ipsum dolor sit amet
          consectetur adipisicing elit. Excepturi, asperiores tenetur animi
          alias fuga provident deleniti praesentium tempore debitis accusamus
          impedit? Quisquam quas vel aliquam, molestias id reprehenderit ipsum
          neque!
        </p>
      </div>

      <div className={"component input"}>
        <div className={"items"}>Badge Red Outline</div>
        <div className={"items"}>
          <div className="pro-badge badge-danger-outline">Due</div>
        </div>
      </div>
      <div className={"component input"}>
        <div className={"items"}>Badge Grey Outline</div>
        <div className={"items"}>
          <div className="pro-badge badge-grey-outline">2/3 Remaining</div>
        </div>
      </div>
      <div className={"component input"}>
        <div className={"items"}>Badge Green Outline</div>
        <div className={"items"}>
          <div className="pro-badge badge-success-outline">Complete</div>
        </div>
      </div>
      <div className={"component input"}>
        <div className={"items"}>Badge Alert</div>
        <div className={"items"}>
          <div className="pro-badge badge-danger">High</div>
        </div>
      </div>
      <div className={"component input"}>
        <div className={"items"}>Badge Tag</div>
        <div className={"items"}>
          <div className="pro-badge badge-grey">Tags / Category</div>
        </div>
      </div>

      <div className="code-area">
        <Highlight>{code}</Highlight>
      </div>
    </div>
  );
};

export default React.memo(BadgesTab);
