import React from 'react'
import Style from './profileSideBar.module.scss'

function ProfileSideBar({extraClassName, children}) {
  return (
    <div className={`${Style.root} ${extraClassName || ''}`}>
      <div className={`${Style.root_inner}`}>
        {children || 'Please add children components'}
      </div>
    </div>
  )
}

export default ProfileSideBar