import React from "react";
import { NavLink } from "react-router-dom";

const NavGroup = ({ navigations, type }) => {
  return (
    <div className="nav nav-2">
      {navigations.map((nav, index) => {
        return (
          <div className="nav-link" key={index}>
            {nav.isButton ? (
              <button className="pro-btn pro-btn-primary">
                <span>{nav.icon}</span> {nav.label}
              </button>
            ) : NavLink ? (
              <NavLink
                to={nav.link}
                className={({ isActive }) =>
                  isActive
                    ? `pro-btn pro-btn-link active`
                    : `pro-btn pro-btn-link`
                }
              >
                {nav.label}
              </NavLink>
            ) : (
              <a href={nav.link} className="pro-btn pro-btn-link">
                {nav.label}
              </a>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default NavGroup;
