import React from "react";
import Style from "./ButtonGroup.module.scss";
import Highlight from "react-highlight";

const code = `<div className='pro-btn-group'>
  <button className='pro-btn pro-btn-outline active'>me</button>
  <button className='pro-btn pro-btn-outline'>All Members</button>
</div>
<div className='pro-btn-group'>
  <button className='pro-btn pro-btn-secondary active'>me</button>
  <button className='pro-btn pro-btn-secondary'>All Members</button>
</div>
<div className='pro-btn-group'>
  <button className='pro-btn pro-btn-primary active'>me</button>
  <button className='pro-btn pro-btn-primary'>All Members</button>
</div>
`;

function ButtonGroupTab() {
  return (
    <div className={Style.buttonWrapper}>
      <div className={"text"}>
        <h3 className={`pro-ttl h3 pro-mb-3`}>Button Group</h3>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi,
          asperiores tenetur animi alias fuga provident deleniti praesentium
          tempore debitis accusamus impedit? Quisquam quas vel aliquam,
          molestias id reprehenderit ipsum neque! Lorem, ipsum dolor sit amet
          consectetur adipisicing elit. Excepturi, asperiores tenetur animi
          alias fuga provident deleniti praesentium tempore debitis accusamus
          impedit? Quisquam quas vel aliquam, molestias id reprehenderit ipsum
          neque!
        </p>
      </div>

      <div className={"component"}>
        <div className={"items"}>
          <div className="pro-btn-group">
            <button className="pro-btn pro-btn-outline active">me</button>
            <button className="pro-btn pro-btn-outline">All Members</button>
          </div>
        </div>
        <div className={"items"}>
          <div className="pro-btn-group">
            <button className="pro-btn pro-btn-secondary active">me</button>
            <button className="pro-btn pro-btn-secondary">All Members</button>
          </div>
        </div>
        <div className={"items"}>
          <div className="pro-btn-group">
            <button className="pro-btn pro-btn-primary active">me</button>
            <button className="pro-btn pro-btn-primary">All Members</button>
          </div>
        </div>
      </div>

      <div className={"component"}>
        <div className={"items"}>
          <div className="pro-btn-group">
            <button className="pro-btn pro-btn-outline lg active">me</button>
            <button className="pro-btn pro-btn-outline lg">All Members</button>
          </div>
        </div>
        <div className={"items"}>
          <div className="pro-btn-group">
            <button className="pro-btn pro-btn-secondary lg active">me</button>
            <button className="pro-btn pro-btn-secondary lg">All Members</button>
          </div>
        </div>
        <div className={"items"}>
          <div className="pro-btn-group">
            <button className="pro-btn pro-btn-primary lg active">me</button>
            <button className="pro-btn pro-btn-primary lg">All Members</button>
          </div>
        </div>
      </div>

      <div className={"text"}>
        <h3 className={`pro-ttl h3 pro-mb-3`}>Bootstrap Button Group</h3>
      </div>
      <div className={"component"}>
        <div className={"items"}>
          <div className="pro-btn-group">
            <button className="pro-btn pro-btn-outline-secondary active">me</button>
            <button className="pro-btn pro-btn-outline-secondary">All Members</button>
          </div>
        </div>
        <div className={"items"}>
          <div className="pro-btn-group">
            <button className="pro-btn pro-btn-secondary active">me</button>
            <button className="pro-btn pro-btn-secondary">All Members</button>
          </div>
        </div>
        <div className={"items"}>
          <div className="pro-btn-group">
            <button className="pro-btn pro-btn-primary active">me</button>
            <button className="pro-btn pro-btn-primary">All Members</button>
          </div>
        </div>
      </div>

      <div className="code-area">
        <Highlight>{code}</Highlight>
      </div>
    </div>
  );
}

export default React.memo(ButtonGroupTab);
