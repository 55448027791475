import React from "react";
import Style from "./DatePickerTab.module.scss";
import Highlight from "react-highlight";
import { addDays } from "date-fns";
import { DatePickerWithInput } from '@wac-ui-dashboard/wac_component_library';

const code = `<DatePickerWithInput range initialValue={dateRange}></DatePickerWithInput>`;

function DatePickerTab() {
  const dateRange = {
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  };
  const dateChange = (date) => { return date};
  return (
    <div className={Style.buttonWrapper}>
      <div className={"text"}>
        <h3  className={`pro-ttl h3 pro-mb-3`}>Date Picker</h3>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Excepturi,
          asperiores tenetur animi alias fuga provident deleniti praesentium
          tempore debitis accusamus impedit? Quisquam quas vel aliquam,
          molestias id reprehenderit ipsum neque! Lorem, ipsum dolor sit amet
          consectetur adipisicing elit. Excepturi, asperiores tenetur animi
          alias fuga provident deleniti praesentium tempore debitis accusamus
          impedit? Quisquam quas vel aliquam, molestias id reprehenderit ipsum
          neque!
        </p>
      </div>

      <div className={"component input"}>
        <div className="items">
          <DatePickerWithInput
            initialValue={dateRange.startDate}
            onDateChange={(val)=>dateChange(val)}
          ></DatePickerWithInput>
        </div>
        <div className="items">
          <DatePickerWithInput
            range
            initialValue={dateRange}
            onDateChange={(val)=>dateChange(val)}
          ></DatePickerWithInput>
        </div>
      </div>
      <div className="component">
        <div className="items pro-w-100">
          <h3>Props</h3>
          <p className="pro-mb-0">Uses date-fns for date operations.</p>
        </div>
        <div className="items pro-w-100">
          <table className="pro-table pro-mb-5">
            <thead>
              <tr>
                <th>Prop</th>
                <th>Description</th>
                <th>Type</th>
                <th>Example</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>initialValue</td>
                <td>Initial value selected in datepicker</td>
                <td>Date, Object (range)</td>
                <td>
                  <div>new Date()</div>
                  <div>{`{ startDate: new Date(), endDate: addDays(new Date(), 7), key: "selection" }`}</div>
                </td>
              </tr>
              <tr>
                <td>range</td>
                <td>Date range picker will show when range prop added</td>
                <td>Boolean</td>
                <td>true or false, default is false</td>
              </tr>
              <tr>
                <td>disabledDates</td>
                <td>Dates that are disabled in calendar</td>
                <td>Array</td>
                <td>[new Date()]</td>
              </tr>
              <tr>
                <td>disabledDay</td>
                <td>Predicate function that disable day</td>
                <td>Function, fn(date: Date)</td>
                <td>
                  <div>{`function(date) { return date < new Date() }`}</div>
                </td>
              </tr>
              <tr>
                <td>minDate</td>
                <td>Defines minimum date. Disabled earlier dates</td>
                <td>Date</td>
                <td>
                  <div>new Date()</div>
                </td>
              </tr>
              <tr>
                <td>maxDate</td>
                <td>Defines maximum date. Disabled later dates</td>
                <td>Date</td>
                <td>
                  <div>new Date()</div>
                </td>
              </tr>
              <tr>
                <td>onDateChange</td>
                <td>Callback function for date changes.</td>
                <td>Date, Object(range)</td>
                <td>
                  <div>{`fn(date: Date), fn({ startDate:Date, endDate:Date })`}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="code-area">
        <Highlight>{code}</Highlight>
      </div>
    </div>
  );
}

export default React.memo(DatePickerTab);
