import React from 'react';
import { ProfileCard } from '@wac-ui-dashboard/wac_component_library'
// import Style from './profileCardTab.module.scss';
function ProfileCardTab() {
    return (
        <div className='col-4'>
            <ProfileCard/>
        </div>
    )
}



export default React.memo(ProfileCardTab);